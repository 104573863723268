import cmsApi from '@/api/cms'
import i18n from '@/i18n'

const parseItemsTranslation = (items) => {
  return items.map(item => parseTranslation(item))
}

const parseTranslation = (item) => ({
  ...item,
  translations: item?.translations ? item?.translations[0] : {}
})

const getItems = async (name, limit, filters = {}, filtersString = '') => {
  const language = i18n.global.locale.value
  const filterString = Object.keys(filters).reduce((prev, cur) => {
    if (!prev) {
      return `&filter[${cur}][_eq]=${filters[cur]}`
    }
    return `${prev}`
  }, '')
  const items = await cmsApi.get(`/${name}?fields=*,translations.*&deep[translations][_filter][languages_code][_eq]=${language}${limit > 0 ? `&limit=${limit}` : ''}${filterString}${filtersString}`)

  return parseItemsTranslation(items.data)
}

const getItem = async (name) => {
  const language = i18n.global.locale.value
  const items = await cmsApi.get(`/${name}?fields=*,translations.*&deep[translations][_filter][languages_code][_eq]=${language}`)

  return parseTranslation(items.data)
}

const getItemById = async (name, id) => {

  const language = i18n.global.locale.value
  const items = await cmsApi.get(`/${name}?fields=*,translations.*&deep[translations][_filter][languages_code][_eq]=${language}&filter[id][_eq]=${id}`)

  return parseTranslation(items.data[0])
}
export { getItem, getItems, getItemById }