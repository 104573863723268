<template>
  <div class="my-order-container responsive-p">
    <div class="top-header-wrapper">
      <button @click="back">
        <img src="@/assets/icons/primary-back.svg" alt="back">
      </button>
      <p class="title">{{ t('order.title') }}</p>
    </div>
    <div class="content-wrapper" v-if="orders.length > 0">
      <OrderCard
        v-for="(item) in orders"
        :key="item.id"
        :item="item"
        @click="goToOrder(item)"
      />
    </div>
    <div class="no-item" v-else>
      <img src="@/assets/images/cart.svg" alt="cart">
      <p class="no-item-text">
        {{ t('myCart.noItem') }}
      </p>
      <MButton outline @click="goToMain">{{ t('myCart.shopNow') }}</MButton>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import OrderCard from '@/components/OrderCard'
import MButton from '@/components/Form/MButton'

export default {
  name: 'MyOrderView',
  components: {
    OrderCard,
    MButton,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    /** computed */
    const orders = computed(() => store.state.order.data)

    /** method */
    const back = () => router.replace('/my-profile')
    const goToOrder = (order) => router.push(`/my-orders/${order.id}`)
    const goToMain = () => router.push('/')

    /** mounted */
    onMounted(() => {
      store.dispatch('order/fetchGetOrders')
    })

    return {
      t,
      back,
      orders,
      goToMain,
      goToOrder,
    }
  }
}

</script>

<style lang="scss" scoped>

.my-order-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
  overflow-y: scroll;
  padding-top: 56px;
  @media screen and (min-width: 780px) {
    padding-top: 0;
    padding-bottom: 0;
    height: calc(100% - 74px);
  }
}

.top-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  @media screen and (min-width: 780px) {
    position: relative;
    border-bottom: 0;
  }
  > button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 12px;
}

.no-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-item-text {
  font-size: 16px;
  margin: 32px 0;
}
</style>