<template>
  <div class="psa-wrapper responsive-p">
    <div class="content-wrapper">
      <div class="wysiwyg" v-html="content" />
    </div>
  </div>
</template>

<script setup>
import { defineComponent, onMounted, ref } from 'vue'

import { getItem } from '@/api/cms/query'

defineComponent({
  name: 'PsaInstruction'
})

const content = ref('')

onMounted(async () => {
  const psaContent = await getItem('psa_instruction')
  if (psaContent?.translations) {
    content.value = psaContent?.translations.content
  }
})
</script>

<style lang="scss" scoped>

.psa-wrapper {
  padding: 24px;
  margin-top: 64px;
  @media screen and (min-width: 780px) {
    margin: 24px;
  }
}
::v-deep(.wysiwyg img) {
  max-width: 100%;
  height: auto;
}
</style>