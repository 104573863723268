<template>
  <div
    class="privacy-popup-container"
    v-if="policy"
  >
    <div class="card-wrapper">
      <div class="header">
        {{ t('term.title') }}
      </div>
      <div class="content wysiwyg" v-html="policy">
      </div>
      <MButton @click="confirmPrivacy">{{ t('term.confirm') }}</MButton>
    </div>
  </div>
</template>

<script>
import { computed, watchEffect, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import MButton from './Form/MButton'

export default {
  components: {
    MButton,
  },
  setup() {
    /** context */
    const store = useStore()
    const { t } = useI18n()

    /** reactive */
    const showTerm = ref(false)
    
    /** computed */
    const userProfile = computed(() => store.getters['user/getUserProfile'])
    const policy = computed(() => store.state.user.termDescription?.policy)
    
    /** method */
    const confirmPrivacy = () => {
      store.dispatch('user/fetchAcceptPrivacy')
    }

    /** watch */
    watchEffect(() => {
      const isAcceptTerm = userProfile.value?.is_accepted_privacy
      if (isAcceptTerm === false) {
        store.dispatch('user/fetchGetTermAndPrivacy')
      }
    })

    return {
      t,
      policy,
      showTerm,
      confirmPrivacy,
    }
  }
}
</script>

<style lang="scss" scoped>

.privacy-popup-container {
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
  }
  background-color: rgba(0, 0, 0, 0.7);
  padding: 24px 12px;
  > .card-wrapper {
    display: flex;
    flex-direction: column;
    > .header {
      font-size: 24px;
      text-align: center;
      margin-bottom: 12px;
      font-weight: bold;
    }
    > .content {
      overflow-y: scroll;
      margin-bottom: 24px;
      font-size: 20px;
    }
    height: 100%;
    overflow: hidden;
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
  }
}

::v-deep(.wysiwyg img) {
  max-width: 100%;
  height: auto;
}
</style>
