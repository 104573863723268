<template>
  <div class="highlight-wrapper">
    <div
      :class="`highlight-item${index % 2 === 0 ? ' reverse' : ''}`"
      v-for="(item, index) in items"
      :key="index"
    >
      <div class="content-wrapper">
        <p class="title">{{ item.translations.title }}</p>
        <span class="description">
          {{ item.translations.description }}
        </span>
        <MButton v-if="item.link" outline @click="onClick(item)">
          {{ item.translations.link_name }}
        </MButton>
      </div>
      <div class="highlight-image">
        <img :src="item.image" :alt="item.translations.title">
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getItems } from '@/api/cms/query'

import MButton from './Form/MButton'

const items = ref([])

const router = useRouter()

onMounted(async () => {
  const highlights = await getItems('highlights', 4, { is_main_highlight: true })
  items.value = highlights.map(highlight => ({
    ...highlight,
    image: `${process.env.VUE_APP_CMS_BASE_IMAGE_URL}/${highlight.preview_image}`,
  }))
})

const onClick = item => {
  if (item.link.startsWith('http')) {
    window.open(item.link, '_blank')
  }
  else {
    router.push(item.link)
  }
}
</script>

<style lang="scss" scoped>
.highlight-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 0 16px;
  max-width: 1440px;
  @media screen and (min-width: 780px) {
    padding: 0 76px;
  }
}

.highlight-item {
  display: flex;
  gap: 12px;
  flex-direction: column-reverse;
  @media screen and (min-width: 780px) {
    flex-direction: row;
    gap: 36px;
    &.reverse {
      flex-direction: row-reverse;
    }
  }
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  > * {
    margin-left: 32px;
  }
  gap: 16px;
}

.highlight-image {
  flex: 1;
  border-radius: 24px;
  overflow: hidden;
  object-fit: cover;
  > img {
    width: 100%;
    height: 100%;
  }
}

.title {
  font-weight: bold;
  font-size: 1.5rem;
}

.description {
  color: #878787;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
