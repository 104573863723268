<template>
  <div class="copyrights-wrapper">
    <div class="wysiwyg" v-html="text"></div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

import { getItem } from '@/api/cms/query'

const text = ref()

onMounted(async () => {
  const copyright = await getItem('copyrights')
  if (copyright?.translations) {
    text.value = copyright.translations.text
  }
})
</script>

<style lang="scss">

.copyrights-wrapper {
  background-color: var(--grey-color);
  padding: 8px 16px;
  * {
    font-size: 12px;
  }
}

::v-deep(.wysiwyg img) {
  max-width: 100%;
  height: auto;
}
</style>
