<template>
  <div class="location-wrapper responsive-p" v-if="item">
    <BackTopBar />
    <NavigationBar :items="navItems" />
    <p class="title">
      {{ item.title }}
    </p>
    <img :src="item.image" alt="" class="location-image">
    <div class="content-wrapper">
      <div class="buttons-wrapper">
        <p>{{ t('location.phone') }}: {{ item.phone }}</p>
        <MButton>
          {{ t('location.book') }}
        </MButton>
      </div>
      <div class="detail-wrapper">
        <div class="description wysiwyg" v-html="item.description" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { getItem, getItemById } from '@/api/cms/query'

import NavigationBar from '@/components/NavigationBar'
import MButton from '@/components/Form/MButton'
import BackTopBar from '@/components/BackTopBar'

defineComponent({
  name: 'LocationById'
})

const route = useRoute()
const { t } = useI18n()

const item = ref({})
const title = ref('')

const navItems = computed(() => [
  {
    name: t('menu.home'),
    path: '/'
  },
  {
    name: title?.value,
    path: '/locations'
  },
  {
    name: item?.value?.title,
  }
])

onMounted(async () => {
  const id = route.params.id
  const locationHeader = await getItem('location_header')
  const location = await getItemById('locations', id)
  title.value = locationHeader.translations.title
  item.value = {
    title: location.translations.name,
    image: `${process.env.VUE_APP_CMS_BASE_IMAGE_URL}/${location.image}`,
    description: location.translations.description,
    phone: location.phone,
    city: location.translations.city,
    link: location?.link,
  }
})
</script>

<style lang="scss" scoped>

.location-wrapper {
  padding: 24px;
  margin-top: 64px;
  @media screen and (min-width: 780px) {
    margin: 24px;
  }
}
.location-image {
  margin: 24px auto;
  width: 100%;
}

.title {
  font-size: 1.8rem;
  font-weight: bold;
}
.description {
}

.detail-wrapper {
  flex: 1;
}
.buttons-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.content-wrapper {
  flex: 1;
  display: flex;
}

::v-deep(.wysiwyg img) {
  max-width: 100%;
  height: auto;
}
</style>
