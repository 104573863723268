<template>
  <div class="multiple-insert-wrapper">
    <label for="label">{{ label }}</label>
    <div class="body-wrapper">
      <div class="items-wrapper" v-if="modelValue.length > 0">
        <div
          class="item"
          v-for="(item, index) in modelValue"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="items-wrapper" v-else>
        <div class="no-data">
          {{ noDataLabel }}
        </div>
      </div>
      <div class="action-wrapper">
        <span @click="openModal" v-if="modelValue.length > 0">{{ t('edit') }}</span>
        <img @click="openModal" src="@/assets/icons/primary-add.svg" alt="primary-add" v-else>
      </div>
    </div>
  </div>
  <div
    class="multiple-insert-modal-wrapper"
    :class="{
      'active': show
    }"
  >
    <div class="card">
      <img src="@/assets/icons/close.svg" alt="close" class="close" @click="close">
      <p class="header">{{ label }}</p>
      <div class="input-wrapper">
        <input type="text" v-model="dataModel" v-on:keyup.enter="add" :placeholder="placeholder">
        <img @click="add" src="@/assets/icons/primary-add.svg" alt="primary-add">
      </div>
      <div class="item-in-modal-wrapper">
        <div
          class="item-in-modal"
          v-for="(item, index) in temps"
          :key="index"
        >
          <span>{{ item }}</span>
          <img @click="remove(index)" src="@/assets/icons/remove.svg" alt="remove">
        </div>
      </div>
      <MButton class="mt-a" @click="save">{{ t('save') }}</MButton>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'

import MButton from './MButton'

export default {
  components: {
    MButton,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    noDataLabel: {
      type: String,
      default: '',
    },
    dataLabel: {
      type: String,
      default: '',
    },
    fillTitle: {
      type: String,
      default: '',
    },
    pleaseFillTitle: {
      type: String,
      default: ''
    },
    modelValue: {
      require: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    /** context */
    const { t } = useI18n()

    /** reactive */
    const show = ref(false)
    const dataModel = ref('')
    const temps = ref([])

    /** method */
    const openModal = () => {
      show.value = true
    }
    const close = () => {
      show.value = false
      dataModel.value = ''
      temps.value = []
    }
    const add = () => {
      if (dataModel.value) {
        temps.value = [ dataModel.value, ...temps.value ]
        dataModel.value = ''
      }
    }
    const remove = (index) => {
      temps.value.splice(index, 1)
    }
    const save = () => {
      emit('update:modelValue', temps.value)
      temps.value = []
      show.value = false
    }

    watchEffect(() => {
      if (show.value) {
        temps.value = [ ...props.modelValue ]
      }
    })

    return {
      t,
      show,
      openModal,
      dataModel,
      add,
      save,
      temps,
      remove,
      close,
    }
  }
}
</script>

<style lang="scss" scoped>
.multiple-insert-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
}

label {
  color: #6A6A6A;
  margin-bottom: 12px;
}

.body-wrapper {
  display: flex;
}

.items-wrapper {
  flex: 1;
  > .item, .no-data {
    color: #878787;
    padding: 0 12px 8px;
    font-weight: normal;
    color: #000000;
    &:last-child {
      padding-bottom: 0;
    }
  }
}

.action-wrapper {
  cursor: pointer;
  > span {
    color: #a2d1d2;
  }
}

.multiple-insert-modal-wrapper {
  position: fixed;
  z-index: 9999;
  top: 100%;
  right: 0;
  left: 0;
  height: 100%;
  padding: 24px;
  transition: transform 0.3s ease;
  &.active {
    transform: translateY(-100%);
  }
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
    &.active {
      transform: translate(-50%, -100%);
    }
  } 
  > .card {
    position: relative;
    .close {
      position: absolute;
      top: 12px;
      left: 12px;
      height: 16px;
      width: 16px;
    }
    background-color: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
    height: 100%;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    .item-in-modal {
      border: 1px solid var(--success-color);
      margin: 0 0 8px;
      padding: 8px 12px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .header {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 24px;
    }
    .item-in-modal-wrapper {
      overflow-y: scroll;
    }
    .input-wrapper {
      min-height: 36px;
      border: 1px solid #DFDFDF;
      border-radius: 8px;
      transition: all 0.3s ease;
      overflow: hidden;
      display: flex;
      margin-bottom: 12px;
      &:focus {
        border: 1px solid var(--secondary-color);
      }
      > input {
        border: 0;
        padding: 8px 12px;
        outline: none;
        width: 100%;
      }
    }
  }
}

img {
  cursor: pointer;
}
</style>
