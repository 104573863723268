<template>
  <div class="my-order-container">
    <div class="top-header-wrapper">
      <button @click="back">
        <img src="@/assets/icons/primary-back.svg" alt="back">
      </button>
      <p class="title">{{ t('referral.header') }}</p>
    </div>
    <div v-if="user">
    <div class="content-wrapper">
      <p class="titleRef">{{ t('referral.ref') }}</p>
    </div>

    <div class="content-wrapper">
      <span class="referral-code-display">
        <p class="titleNumberRef">{{ user.id }}</p>
        <button class="right-button" @click="copyUserIdToClipboard">{{ t('referral.copy') }}</button>
      </span>
    </div>

    <div class="two-column-wrapper">
      <div class="column">


        <div class="two-column-wrapper">
          <div class="column2">
            <img src="@/assets/icons/m-coin.svg" alt="logo" class="img-size">
          </div>
          <div class="column2 right-object">
            <div class="right-number">
              {{ numberWithCommas(user?.coin) }}
            </div>
            <br />
            <div class="right-text">
              {{ t('referral.myscore') }}
            </div>
          </div>
        </div>

      </div>
      <div class="column">


        <div class="two-column-wrapper">
          <div class="column2">
            <img src="@/assets/icons/threehuman.png" alt="logo" class="img-size">
          </div>
          <div class="column2 right-object">
            <div class="right-number">
              {{ refCount }}
            </div>
            <br />
            <div class="right-text">
              {{ t('referral.friend') }}
            </div>
          </div>
        </div>

      </div>

     
    </div>

    <div class="inputRefCode">
      <div class="title">{{ t('checkout.placeHolderReferalCode') }}</div><br/>
      <TextInputBold :placeholder="t('checkout.placeHolderReferalCode')" v-model="refCode"  class="text-inbox"/>
      <button class="right-button" @click="submitReferral">{{ t('referral.done') }}</button>
    </div>

    <div class="titleMiddle">
      {{t('referral.history')}}
    </div>
    
    <div
      class="history-entry"
      :class="{'not-include' : !includeTypes.includes(entry.type)}"
      v-for="(entry, index) in referralHistory" :key="index"
    >
      <div v-if="entry.type == 'MKL'">
          <div class="history-detail">
            <p class="history-date">{{t('referral.useCode') }}</p><br/>
            <p class="history-info">
              {{  entry.date_claim }} | {{t('referral.useCodeDesc') }}
            </p>
            <p class="history-info">
              Referrer ID: {{ entry.referrer_id }}
            </p>
          
          </div>
          <button class="history-score-button">
            <div v-if="entry.reward_id == 1">
              100 {{t('referral.point') }}
            </div>
          </button>
      </div>

      <div v-else-if="entry.type == 'QR_CODE'">
          <div class="history-detail">
            <p class="history-date">{{t('referral.useQRCode') }}</p><br/>
            <p class="history-info">
              {{  entry.date_claim }} | {{t('referral.useQRCodeDesc') }}
            </p>
            <p class="history-info">
              Referrer ID: {{ entry.referrer_id }}
            </p>
          
          </div>
          <button class="history-score-button">
            <div v-if="entry.reward_id == 1">
              {{entry.order_id}} {{t('referral.point') }}
            </div>
          </button>
      </div>

    </div>
    
    
    
  </div>
   

  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {useI18n} from 'vue-i18n'
import numberWithCommas from '@/utils/numberWithCommas'
import referralApi from '@/api/referral';
import patrickApi from '@/api/patrick';
import userApi from '@/api/user'

import TextInputBold from '@/components/Form/TextInputBold'

export default {
  name: 'ReferralView',
  components: {
    TextInputBold
  },
  setup() {
    /** context */
    const store = useStore()
    const refCode = ref('')
    let refCount = ref(0)
    let referralHistory = ref([])
    const router = useRouter()
    const {t} = useI18n()
    const includeTypes = [ 'MKL', 'QR_CODE' ]

    /** computed */
    const user = computed(() => store.getters['user/getUserProfile'])

    /** method */
    const back = () => router.replace('/my-profile')
    const goToMain = () => router.push('/')

    const redirectToLogin = () => {
      localStorage.setItem('redirect', '/referral')
      router.replace('/user/login')
    }

    onMounted(async () => {
      try {
        await store.dispatch('user/fetchGetUserProfile')
        if (!store.getters['user/getUserProfile']) {
          redirectToLogin();
        }
      } catch (e) {
        console.error("Error fetching user data: ", e);
        redirectToLogin();
      }
    })

    watch(user, (newValue) => {
  if (newValue && newValue.id) { 
    console.log("User data is now available", newValue);
    performUserDependentActions(newValue);
  }
}, { immediate: false });

const performUserDependentActions = async (userData) => {
  try {
    console.log("Performing actions with user data:", userData);
    const rewardsResponse = await referralApi.getUserOwnerRewards(userData.id);
    refCount.value = rewardsResponse.length;
    referralHistory.value = rewardsResponse;
    console.log("Rewards fetched successfully:", rewardsResponse);
  } catch (error) {
    console.error('Error fetching user rewards:', error);
    
  }
};

    const submitReferral = async () => {
  const rewardData = {
    userId: refCode.value,
    rewardId: 1, 
    dateClaim: new Date().toISOString().split('T')[0],
    type: 'MKL',
    referrerId: user.value.id,
    order_id: -1,
    status: 'CLAIMED'};

    if(refCode.value == user.value.id){
      alert('ไม่สามารถใส่รหัสผู้แนะนำของตัวเองได้');
      return;
    }

    const checkOrder = await userApi.checkOrder(user.value.id);
    if(checkOrder.Status == false){
      
      alert('รหัสผู้แนะนำสามารถใช้ได้กับลูกค้าใหม่ที่ไม่เคยใช้บริการกับทางคลินิกเท่านั้น');
      return;
    }
    try{
      const visitData = await patrickApi.getVisit(user.value.makalin_user_id);
      console.log(visitData)
      if(visitData.Status == false){
        alert('รหัสผู้แนะนำสามารถใช้ได้กับลูกค้าใหม่ที่ไม่เคยใช้บริการกับทางคลินิกเท่านั้น');
        return;
      }
    }catch(e){
      console.log(e)
    }


  try {
    
    const rewards = await referralApi.getUserRewards(user.value.id);
    if(rewards.length == 0){

      const addCoin = await userApi.addCoinRefferal(refCode.value, "100.00");
      if(addCoin){
        const result = await referralApi.postUserReward(rewardData);
        if(result){
          alert('ใส่ผู้แนะนำสำเร็จ');
        }
      }else{
        alert('ไม่พบผู้ใช้งานกรุณาลองใหม่อีกครั้ง');
      }

    }else{
      alert('ขออภัยคุณได้ใส่ผู้แนะนำแล้ว');
    }
  } catch (error) {
    console.error('Error posting referral:', error);
  }
};


    const copyUserIdToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(user.value.id)
      } catch (err) {
        console.error('Failed to copy: ', err)
      }
    }

    return {
      t,
      back,
      user,
      refCode,
      goToMain,
      copyUserIdToClipboard,
      numberWithCommas,
      submitReferral,
      refCount,
      referralHistory,
      includeTypes,
    }
  }
}

</script>

<style lang="scss" scoped>
.my-order-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
  overflow-y: scroll;
  padding-top: 56px;
}

.top-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
  }

  >button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
  }
}

.img-size {
  width: 50px;
  height: 50px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.titleMiddle {
  font-size: 25px;
  font-weight: bold;
  z-index: 10; // Make sure it's above other content if necessary
  width: 100%; // Ensures it spans the full width if needed for center alignment
  text-align: center; // Ensures the text within is centered
}

.titleRef {
  font-size: 24px;
  font-weight: bold;
  color: #4A58E5;
  margin-left: 20px;
  margin-top: 10px;
}

.titleNumberRef {
  font-size: 40px;
  font-weight: bold;
  margin-left: 20px;
}


.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 12px;
}

.no-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-item-text {
  font-size: 16px;
  margin: 32px 0;
}

.referral-code-display {
  display: flex;
  align-items: center; // Centers the items vertically
  justify-content: space-between; // Places space between the items
  width: 100%; // Ensures the span takes the full width of its container
}

.titleNumberRef {
  font-size: 40px;
  font-weight: bold;
  margin-left: 20px; // Adjust according to your design needs
}

.right-button {
  margin-right: 20px; // Adjust according to your design needs
  padding: 8px 16px; // Adds padding inside the button for better touch target
  background-color: var(--button-color, #4A58E5); // Uses a CSS variable or fallback color
  color: white; // Text color for the button
  border: none;
  border-radius: 4px; // Optional: Adds rounded corners to the button
  cursor: pointer; // Changes the cursor to a pointer when hovered over the button
}

.right-object {
  display: flex;
  flex-direction: column;
  align-items: flex-start; // Ensures that the text alignment follows the flex start position
}

.right-text {
  margin-left: auto; // Pushes the container to the right
  align-self: flex-end; // Aligns the text to the end of the flex container
  color: #878787;
  font-size: 15px;
}

.right-number {
  margin-left: auto;
  align-self: flex-end;
  color: black;
  font-size: 30px;
}



.two-column-wrapper {
  display: flex;
  justify-content: space-between; // Distributes space evenly between the columns
}

.inputRefCode {
  margin: 20px;
}


.two-column-wrapper2 {
  display: flex;
  justify-content: space-between; // Distributes space evenly between the columns
}

.history-entry {
  position: relative; // Allows absolute positioning relative to this container
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center; // Ensures vertical centering of content
  justify-content: space-between; // For layout spacing
  &.not-include {
    display: none;
  }
}

.history-content {
  flex-grow: 1; // Allows the content to fill the space pushing the button to the end
}

.history-date, .history-info {
  margin-bottom: 5px;
}

.history-date {
  font-weight: bold;
  color: #333;
}

.history-info {
  color: #666;
}

.history-score-button {
  position: absolute; // Absolute position within the relative container
  top: 10px; // Distance from the top of the container
  right: 10px; // Distance from the right of the container
  padding: 5px 10px;
  background-color: #4A58E5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.column {
  flex: 1; // Each column takes up an equal amount of space
  padding: 10px; // Adds some padding around the content of each column
  border: 1px solid #4A58E5; // Adds a border for visual separation
  border-radius: 10px;
  margin: 5px; // Optional: Adds margin between the columns if needed
}

.history-score-button {
  padding: 5px 10px; // Padding inside the button for a better touch target
  background-color: #4A58E5; // Color matching the design
  color: white; // Text color for the button
  border: none;
  border-radius: 5px; // Rounded corners for the button
  cursor: pointer; // Changes the cursor to a pointer when hovered over
}

.column2 {
  flex: 1; // Each column takes up an equal amount of space
}
</style>