<template>
  <div class="blog-wrapper responsive-p" v-if="item">
    <!-- <BackTopBar /> -->
    <NavigationBar :items="navItems" />
    <p class="title">
      {{ item.title }}
    </p>
    <img :src="item.image" alt="" class="blog-image">
    <div class="content-wrapper">
      <div class="detail-wrapper">
        <p class="created-by">{{ t('blog.createdBy') }}: <b>{{ item.author }}</b></p>
        <div class="wysiwyg" v-html="item.content" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { getItem, getItemById } from '@/api/cms/query'

import NavigationBar from '@/components/NavigationBar'
// import BackTopBar from '@/components/BackTopBar'

defineComponent({
  name: 'BlogById'
})

const route = useRoute()
const { t } = useI18n()

const item = ref({})
const title = ref('')

const navItems = computed(() => [
  {
    name: t('menu.home'),
    path: '/'
  },
  {
    name: title?.value,
    path: '/blogs'
  },
  {
    name: item?.value?.title,
  }
])

onMounted(async () => {
  const id = route.params.id
  const blogHeader = await getItem('blog_header')
  const blog = await getItemById('blogs', id)
  title.value = blogHeader.translations.title
  item.value = {
    title: blog.translations.name,
    image: `${process.env.VUE_APP_CMS_BASE_IMAGE_URL}/${blog.preview_image}`,
    author: blog.translations.author,
    content: blog.translations.content,
    description: blog.translations.description,
  }
})
</script>

<style lang="scss" scoped>

.blog-wrapper {
  padding: 24px;
  margin-top: 64px;
  @media screen and (min-width: 780px) {
    margin: 24px;
  }
}
.blog-image {
  display: block;
  margin: 24px auto;
  width: 100%;
  max-width: 920px;
}

.title {
  font-size: 1.8rem;
  font-weight: bold;
}
.description {
  font-size: 1.4rem;
  text-align: center;
}

.detail-wrapper {
  flex: 1;
}
.created-by {
  text-align: right;
}

::v-deep(.wysiwyg img) {
  max-width: 100%;
  height: auto;
}
</style>
