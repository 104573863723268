<template>
  <div class="top-bar-wrapper not-transparent" v-if="isDesktop">
    <img src="@/assets/images/logo.png" alt="top-bar-logo" class="top-bar-logo cursor-pointer" @click="goToHome">
    <SearchInput />
    <div class="menus-wrapper">
      <a
        class="text-menu-item cursor-pointer"
        v-for="(item, index) in desktopMenuItems"
        :key="index"
        @click="onClickMenu(item)"
      >
        <p v-if="item?.translations?.name">
          {{ item.translations.name }}
        </p>
      </a>
    </div>
    <button class="icon" @click="goToCart">
      <img src="@/assets/icons/basket-black.svg" alt="cart">
      <span class="amount" v-if="totalItemAmount > 0">
        {{ totalItemAmount }}
      </span>
    </button>
    <button class="icon" @click="goToMyDrugs">
      <img src="@/assets/icons/my-drugs-black.svg" alt="cart">
    </button>
    <button @click="goToProfile" class="account-btn">
      <img :src="user?.thumbnail_url || require('@/assets/logo.jpg')" alt="profile" class="profile-img">
      <p>{{ user?.first_name ? user?.first_name : user?.display_name ? user?.display_name : t('login') }}</p>
    </button>
    <LanguageSwitcher black />
  </div>
  <div v-if="!isDesktop" :class="`mobile-menus-wrapper${isExpanded ? ' expanded' : ''}`">
    <div
      class="hamburger"
      @click="toggleMobileMenu"
    >
      <div />
      <div />
      <div />
    </div>
    <div class="mobile-menu-items-wrapper">
      <a>
        <LanguageSwitcher black />
      </a>
      <a
        v-for="(item, index) in mobileMenuItems"
        :key="index"
        @click="onClickMenu(item)"
      >
        {{ item.translations.name }}
      </a>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { getItems } from '@/api/cms/query'
import { useScreenSize } from '@/utils/isDesktop'

import LanguageSwitcher from './LanguageSwitcher'
import SearchInput from '@/components/SearchInput'

export default {
  components: {
    LanguageSwitcher,
    SearchInput,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    // const route = useRoute()
    const { t } = useI18n()
    const resMenuItems = ref([
      {
        icon: 'basket-black.svg',
        path: '/my-cart',
        showAmount: true,
      },
      {
        icon: 'line-black.svg',
        onClick: () => {
          window.open('https://lin.ee/jr5To5P', '_blank');
        }
      }
    ])

    const desktopMenuItems = computed(() => resMenuItems.value.filter(item => item.is_desktop))
    const mobileMenuItems = computed(() => resMenuItems.value.filter(item => item.is_mobile))
    const scrollY = ref(window.scrollY)
    const isExpanded = ref(false)
    /** computed */
    const user = computed(() => store.getters['user/getUserProfile'])
    const totalItemAmount = computed(() => store.getters['cart/totalItemAmount'])
    // const isAtTop = computed(() => scrollY.value === 0 && [ '/', '/products' ].includes(route.path))

    const { isDesktop } = useScreenSize()
    /** method */
    const goToHome = () => {
      router.push('/')
    }
    const goToProfile = () => {
      if (user.value) {
        router.push('/my-profile')
      }
      else {
        router.push('/user/login')
      }
    }
    const onClickMenu = (item) => {
      if (item.path) {
        router.push(item.path)
      }
      else if (item.onClick) {
        item.onClick()
      }
      if (isExpanded.value) {
        isExpanded.value = false
      }
    }
    const updateScrollY = () => {
      scrollY.value = window.document.getElementById('app').scrollTop
    }
    const toggleMobileMenu = () => {
      isExpanded.value = !isExpanded.value
    }
    const goToCart = () => {
      router.push('/my-cart')
    }
    const goToMyDrugs = () => {
      router.push('/my-drugs')
    }

    onMounted(async () => {
      store.dispatch('user/fetchGetUserProfile')
      window.document.getElementById('app').addEventListener('scroll', updateScrollY)
      const menuItems = await getItems('top_bar_menus')
      resMenuItems.value = [ ...menuItems, ...resMenuItems.value ]
    })
    
    return {
      t,
      isDesktop,
      totalItemAmount,
      onClickMenu,
      user,
      goToProfile,
      goToHome,
      // isAtTop,
      isExpanded,
      toggleMobileMenu,
      mobileMenuItems,
      desktopMenuItems,
      goToCart,
      goToMyDrugs,
    }
  }
}
</script>

<style lang="scss" scoped>

.top-bar-wrapper {
  padding: 12px 10px;
  display: flex;
  align-items: center;
  z-index: 100;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #FFFFFF00;
  transition: all 0.3s ease;
}

.top-bar-logo {
  width: 54px;
  @media screen and (max-width: 1220px) {
    width: 48px;
  }
  @media screen and (max-width: 1024px) {
    width: 40px;
  }
}

.menus-wrapper {
  display: flex;
  align-items: center;
  background-color: #FFFFFF1A;
  gap: 16px;
  border-radius: 40px;
  padding: 12px 36px;
  height: 58px;
  @media screen and (max-width: 1220px) {
    height: 50px;
    padding: 12px 24px;
  }
  @media screen and (max-width: 1024px) {
    height: 42px;
    padding: 12px 18px;
  }
}

.text-menu-item {
  color: #FFFFFF;
  font-weight: 100;
  white-space: nowrap;
  > p {
    font-size: 1rem;
    @media screen and (max-width: 1220px) {
      font-size: 0.9rem;
    }
    @media screen and (max-width: 1024px) {
      font-size: 0.8rem;
    }
  }
}

.icon {
  border: 0;
  outline: none;
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1220px) {
    > img {
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: 1024px) {
    > img {
      width: 20px;
      height: 20px;
    }
  }
  &:active {
    opacity: 0.8;
  }
  .amount {
    position: absolute;
    border-radius: 50%;
    top: 0;
    right: 0;
    transform: translate(50%, -25%);
    width: 18px;
    display: flex;  
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    height: 18px;
    background-color: var(--secondary-color);
    color: var(--white-color);
    @media screen and (max-width: 1220px) {
      width: 14px;
      height: 14px;
      font-size: 0.7rem;
    }
  }
}

.account-btn {
  height: 58px;
  > img {
    width: 24px;
    height: 24px;
    border-radius: 24px;
  }
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 40px;
  gap: 8px;
  @media screen and (max-width: 1220px) {
    height: 50px;
    > p {
      font-size: 0.9rem;
    }
  }
  @media screen and (max-width: 1024px) {
    height: 42px;
    > p {
      font-size: 0.8rem;
    }
  }
}

.not-transparent {
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  .text-menu-item {
    color: #000000;
  }
}

.mobile-menus-wrapper {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(calc(100% - 60px));
  display: flex;
  align-items: flex-start;
  transition: all 0.7s ease-in-out;
  gap: 30px;
  .hamburger {
    transform: translateY(10px);
    padding: 4px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 40px;
    min-width: 40px;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    > div {
      height: 3px;
      width: 24px;
      background-color: #000000;
      border-radius: 2px;
      transition: all 0.3s ease;
    }
    &:active {
      opacity: 0.7;
    }
  }
  &.expanded {
    transform: translateX(0);
    .hamburger {
      > div:nth-child(1) {
        transform-origin: center;
        transform: translate(0, 7px) rotate(45deg);
      }
      > div:nth-child(2) {
        opacity: 0;
      }
      > div:nth-child(3) {
        transform-origin: center;
        transform: translate(0, -7px) rotate(-45deg);
      }
    }
  }
}

.mobile-menu-items-wrapper {
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  > a {
    padding: 0 16px 16px;
    border-bottom: 1px solid var(--grey-color);
    &:first-child {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.cart-icon {
  padding: 8px 0;
}
</style>
