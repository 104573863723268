<template>
  <div class="location-section" v-show="items.length > 0">
    <div class="card">
      <p class="title">{{ title }}</p>
      <span class="description">
        {{ description }}
      </span>
      <m-button outline @click="goToAllLocation">
        {{  t('location.explore') }}
      </m-button>
    </div>
    <div
      class="card location-item"
      v-for="(item, index) in items"
      :key="index"
    >
      <div class="img-wrapper">
        <picture>
          <img class="image" :src="item.image" :alt="item.title">
        </picture>
        <div class="text-wrapper">
          <p class="name" @click=goToLocation(item)>{{ item.title }} ></p>
          <p class="city">{{ item.city }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { getItem, getItems } from '@/api/cms/query'

import MButton from './Form/MButton'
import { useRouter } from 'vue-router'

const { t } = useI18n()

const items = ref([])
const title = ref('')
const description = ref('')

onMounted(async () => {
  const locations = await getItems('locations', 5)
  const locationHeader = await getItem('location_header')
  if (locationHeader) {
    title.value = locationHeader.translations.title
    description.value = locationHeader.translations.description
  }
  items.value = locations.map(location => {
    return {
      title: location.translations.name,
      image: `${process.env.VUE_APP_CMS_BASE_IMAGE_URL}/${location.image}`,
      city: location.translations.city,
      link: location?.link,
      id: location.id,
    }
  })
})

const router = useRouter()

const goToAllLocation = () => {
  router.push('/locations')
}

const goToLocation = (item) => {
  router.push(`/locations/${item.id}`)
}
</script>

<style lang="scss" scoped>

.location-section {
  max-width: 1440px;
  display: grid;
  padding: 32px 0;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding: 0 16px;
  @media screen and (min-width: 780px) {
    padding: 0 76px;
  }
  @media screen and (max-width: 435px) {
    grid-template-columns: 1fr;
  }
  @media screen and (min-width: 780px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.card {
  display: flex;
  flex-direction: column;
  min-width: 180px;
  gap: 16px;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
}

.title {
  font-size: 1.8rem;
  font-weight: bold;
}

.description {
  font-size: 1.2rem;
}

.img-wrapper {
  aspect-ratio: 4 / 3;
}
.location-item {
  padding: 0;
  border-radius: 16px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #FFFFFF;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 60%);
  padding: 120px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .name {
    font-size: 1.6rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .city {
    font-size: 1rem;
  }
}
</style>
