import i18n from '@/i18n'

const initiate = {
  text: '',
  priceFrom: null,
  priceTo: null,
  sorting: { id: 1, title: i18n.global.t('filter.name'), value: 'name' },
  categories: []
}

export default {
  namespaced: true,
  state: {
      data: { ...initiate },
  },
  getters: {
  },
  actions: {
  },
  mutations: {
    SET_TEXT(state, data) {
      state.data.text = data
    },
    RESET_CATEGORY(state) {
      state.data.categories = []
    },
    SET_CATEGORY(state, data) {
      const found = state.data.categories.includes(data)
      if (found) {
        state.data.categories.pop(data)
      }
      else {
        state.data.categories.push(data)
      }
    },
    SET_PRICE_FROM(state, data) {
      state.data.priceFrom = data
    },
    SET_PRICE_TO(state, data) {
      state.data.priceTo = data
    },
    SET_SORTING(state, data) {
      state.data.sorting = data
    },
    RESET_ALL(state) {
      state.data = { ...initiate }
    }
  },
}
