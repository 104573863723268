<template>
  <div class="into-wrapper">
    <div
      class="content wysiwyg"
      v-html="content"
    />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { getItem } from '@/api/cms/query'

const content = ref('')
onMounted(async () => {
  const intoResponse = await getItem('into')
  content.value = intoResponse.translations.content
})

</script>

<style lang="scss" scoped>

.into-wrapper {
  padding: 16px 16px 0;
  max-width: 1440px;
  @media screen and (min-width: 780px) {
    padding: 0 76px;
  }
}
.content {
}

::v-deep(.wysiwyg img) {
  max-width: 100%;
  height: auto;
}
</style>