<template>
  <div class="check-out-wrapper responsive-p desktop" v-if="isDesktop">
    <div class="desktop-wrapper">
      <div class="content-wrapper">
        <NavigationBar :items="navItems" />
        <div class="product-title">
          <img src="@/assets/icons/down-black.svg" alt="down" @click="toggleShowProducts" :class="`${expanded ? 'active' : ''}`">
          <p @click="toggleShowProducts">
            {{ t('checkout.items') }} ({{ itemsInCart.length }})
          </p>
        </div>
        <div class="items-wrapper" v-if="itemsInCart.length > 0 && expanded">
          <ProductInCheckoutCard v-for="(item) in itemsInCart" :key="item.cart_item_id" :item="item" />
        </div>
        <p class="header">
          {{ t('checkout.shipping') }}
        </p>
        <span class="change-address" @click="goToSelectAddress" v-if="addresses.length > 0">{{ t('checkout.changeAddress')
        }}</span>
        <span class="change-address" @click="goToAddAddress" v-else>{{ t('checkout.goToAddAddress') }}</span>
        <AddressCard v-if="selectedAddress" :address="selectedAddress" hiddenRemove display />
        <div class="shipping-item" @click="clickSendByPost" v-else>
          <div class="not-selected">
            <p class="title">{{ t('checkout.sendByPost') }}</p>
            <p class="go">></p>
          </div>
        </div>
        <!-- <div class="shipping-item">
          <div class="not-selected">
            <p class="title">{{ t('checkout.takeaway') }}</p>
            <p class="go">></p>
          </div>
        </div> -->
      </div>
      <div class="desktop-total-wrapper">
        <p>{{ t('myCart.order') }}</p>
        <SelectCoupon />
        <div v-if="getUser" class="middle-wrapper">
          <span class="referral-code-wrapper">
            <img src="@/assets/icons/m-coin.svg" alt="M-Coin" class="m-coin-icon">
            <TextInputBold :placeholder="t('checkout.placeHolderReferalCode')" v-model="refCode" :label="t('checkout.referalCode')"  class="text-inbox"/>
            <button class="right-button" @click="submitReferral">{{ t('referral.done') }}</button>  
          </span>
        </div>
        <div class="break-line"></div>
        <p class="header">
          {{ t('checkout.total') }}
        </p>
        <div class="total-content-wrapper">
          <div class="row">
            <p class="title">{{ t('checkout.sumTotal') }}</p>
            <p class="amount">{{ numberWithCommas(total) }} ฿</p>
          </div>
          <div class="row">
            <p class="title">{{ t('checkout.shippingFee') }}</p>
            <p class="amount">{{ numberWithCommas(shippingFee) }} ฿</p>
          </div>
          <div class="row">
            <p class="title">{{ t('checkout.sumDiscount') }}</p>
            <p class="amount discount">{{ discount ? `-${numberWithCommas(discount)}` : numberWithCommas(discount) }} ฿</p>
          </div>
          <div class="row">
            <p class="title">{{ t('checkout.sumGrandTotal') }}</p>
            <p class="amount grand-total">{{ numberWithCommas(grandTotal) }} ฿</p>
          </div>
        </div>
        <div class="break-line"></div>
        <div v-if="!getUser">
          <p class="header">
            {{ t('checkout.email') }}
          </p>
          <div class="payment-wrapper">
            <TextInput v-model="email" :label="t('checkout.emailDetail')" required />
          </div>
        </div>
        <p class="header">
          {{ t('checkout.payment') }}
        </p>
        <div class="payment-wrapper">
          <div class="payment-item" v-for="(item) in paymentChannels" :key="item.value"
            :class="{ 'selected': item.value === selectedPayment }" @click="selectedPayment = item.value">
            {{ item.title }}
          </div>
        </div>
        <div class="paragraph">
          {{ t('checkout.acceptParagraph') }}
        </div>
        <MButton success @click="createOrder">
          {{ t('checkout.pay') }}
        </MButton>
      </div>
    </div>
  </div>
  <div class="check-out-wrapper" v-else>
    <div class="top-header-wrapper">
      <button @click="backToCart">
        <img src="@/assets/icons/primary-back.svg" alt="back">
      </button>
      <p class="title">{{ t('checkout.title') }}</p>
    </div>
    <div class="content-wrapper">
      <p class="header">
        {{ t('checkout.shipping') }}
      </p>
      <span class="change-address" @click="goToSelectAddress" v-if="addresses.length > 0">{{ t('checkout.changeAddress')
      }}</span>
      <span class="change-address" @click="goToAddAddress" v-else>{{ t('checkout.goToAddAddress') }}</span>
      <AddressCard v-if="selectedAddress" :address="selectedAddress" hiddenRemove display />
      <div class="shipping-item" @click="clickSendByPost" v-else>
        <div class="not-selected">
          <p class="title">{{ t('checkout.sendByPost') }}</p>
          <p class="go">></p>
        </div>
      </div>
      <!-- <div class="shipping-item">
        <div class="not-selected">
          <p class="title">{{ t('checkout.takeaway') }}</p>
          <p class="go">></p>
        </div>
      </div> -->
      <p class="header mt-2">
        {{ t('checkout.items') }}
      </p>
      <div class="items-wrapper" v-if="itemsInCart.length > 0">
        <ProductInCheckoutCard v-for="(item) in itemsInCart" :key="item.cart_item_id" :item="item" />
      </div>
      <SelectCoupon />
      <div class="break-line"></div>
      <p class="header">
        {{ t('checkout.total') }}
      </p>
      <div class="total-content-wrapper">
        <div class="row">
          <p class="title">{{ t('checkout.sumTotal') }}</p>
          <p class="amount">{{ numberWithCommas(total) }} ฿</p>
        </div>
        <div class="row">
          <p class="title">{{ t('checkout.shippingFee') }}</p>
          <p class="amount">{{ numberWithCommas(shippingFee) }} ฿</p>
        </div>
        <div class="row">
          <p class="title">{{ t('checkout.sumDiscount') }}</p>
          <p class="amount discount">{{ discount ? `-${numberWithCommas(discount)}` : numberWithCommas(discount) }} ฿</p>
        </div>
        <div class="row">
          <p class="title">{{ t('checkout.sumGrandTotal') }}</p>
          <p class="amount grand-total">{{ numberWithCommas(grandTotal) }} ฿</p>
        </div>
      </div>
      <div class="break-line"></div>

      <div v-if="!getUser">
        <p class="header">

          {{ t('checkout.email') }}
        </p>
        <div class="payment-wrapper">
          <TextInput v-model="email" :label="t('checkout.emailDetail')" required />
        </div>

        <br />
      </div>

      <div v-if="getUser" class="middle-wrapper">
        <span class="referral-code-wrapper">
          <img src="@/assets/icons/m-coin.svg" alt="M-Coin" class="m-coin-icon">
          <TextInputBold :placeholder="t('checkout.placeHolderReferalCode')" v-model="refCode" :label="t('checkout.referalCode')"  class="text-inbox"/>
          <button class="right-button" @click="submitReferral">{{ t('referral.done') }}</button>  
        </span>
      </div>

      <br />

      <p class="header">
        {{ t('checkout.payment') }}
      </p>
      <div class="payment-wrapper">
        <div class="payment-item" v-for="(item) in paymentChannels" :key="item.value"
          :class="{ 'selected': item.value === selectedPayment }" @click="selectedPayment = item.value">
          {{ item.title }}
        </div>
      </div>
      <div class="paragraph">
        {{ t('checkout.acceptParagraph') }}
      </div>
      <MButton success @click="createOrder">
        {{ t('checkout.pay') }}
      </MButton>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/Form/TextInput'
import TextInputBold from '@/components/Form/TextInputBold'
import {computed, onMounted, ref, nextTick} from 'vue'
import {toRaw} from 'vue';
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {useI18n} from 'vue-i18n'
import numberWithCommas from '@/utils/numberWithCommas'
import { useScreenSize } from '@/utils/isDesktop'

import ProductInCheckoutCard from '@/components/ProductInCheckoutCard'
import SelectCoupon from '@/components/SelectCoupon'
import MButton from '@/components/Form/MButton'
import AddressCard from '@/components/AddressCard'
import NavigationBar from '@/components/NavigationBar'

import referralApi from '@/api/referral'
import userApi from '@/api/user'
import patrickApi from '@/api/patrick'
export default {
  name: 'CheckoutView',
  components: {
    TextInput,
    TextInputBold,
    ProductInCheckoutCard,
    SelectCoupon,
    MButton,
    AddressCard,
    NavigationBar,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const refCode = ref('')
    const {t} = useI18n()
    const { isDesktop } = useScreenSize()
    const expanded = ref(true)
    /** computed */
    const itemsInCart = computed(() => store.state.cart.data?.cart_items || 0)
    const total = computed(() => store.state.cart.data?.cart_calculated?.total || 0)
    const grandTotal = computed(() => store.state.cart.data?.cart_calculated?.grand_total || 0)
    const shippingFee = computed(() => store.state.cart.data?.cart_calculated?.shipping_fee || 0)
    const discount = computed(() => store.state.cart.data?.cart_calculated?.discount || 0)
    const vat = computed(() => store.state.cart.data?.cart_calculated?.vat || 0)
    const vatPercent = computed(() => store.state.cart.data?.cart_calculated?.vat_percent || 0)
    const countItem = computed(() => store.state.cart.data?.cart_calculated?.count_cart_item || 0)
    const email = ref('')
    const paymentChannels = computed(() => !/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? store.state.cart.data?.payment_channels.filter(c => c.value !== 'scb_easy_app') : store.state.cart.data?.payment_channels || [
      {
        "title": "QR Code",
        "value": "scb_qr_code"
      },
      {
        "title": "SCB Easy App",
        "value": "scb_easy_app"
      },
      {
        "title": "บัตรเครดิต / บัตรเดบิต",
        "value": "credit_card"
      }
    ])
    const discountInfo = computed(() => store.state.cart.data?.coupon_code)
    const selectedAddress = computed(() => store.getters['user/getSelectedAddress'])
    const addresses = computed(() => store.state.user.addresses || [])
    const getUser = computed(() => store.getters['user/getUser'])
    const user = computed(() => store.getters['user/getUserProfile'])
    const navItems = computed(() => {
      return [
        {
          name: t('myCart.title'),
          path: '/'
        },
        {
          name: t('checkout.title')
        },
      ]
    })

    /** mounted */
    onMounted(() => {
      try {
        store.dispatch('user/fetchGetUserProfile')
      } catch (e) {
        console.log(e)
      }

    })

    store.dispatch('cart/fetchGetCart')

    const selectedPayment = computed({
      get: () => store.state.cart.data?.selectedPayment,
      set: (val) => store.commit('cart/SET_SELECTED_PAYMENT', val)
    })

    const disabledPayButton = computed(() => {
      if (!selectedPayment.value) return true
      return false
    })

    /** method */
    const backToCart = () => router.replace('/my-cart')
    const clickSendByPost = () => {
      router.push('/my-addresses')
    }
    const createOrder = () => {
      
      if(refCode.value != ""){
        if(!submitReferral()){
          return;
        }else{
          localStorage.setItem('refCode', refCode.value);
        }
      }

      try{
        store.dispatch('user/fetchGetUserProfile').then(() => {
      const user = store.getters['user/getUserProfile'];

    const makalinUser = JSON.parse(localStorage.getItem('makalin-user')) || {};
    let utmParams = {
      "userID": user.id,
      "ads": localStorage.getItem('ads_url') || "",
      "ads_date": localStorage.getItem('ads_date') || "",
      "created": new Date().toISOString(),
      "item_in_cart": toRaw(itemsInCart.value),
      "total": total.value,
      "grand_total": grandTotal.value,
      "shipping_fee": shippingFee.value,
      "discount": discount.value,
      "vat": vat.value,
      "vat_percent": vatPercent.value,
      "count_item": countItem.value,
      "payment_channel": selectedPayment.value,
      "email": email.value,
      "coupon_code": discountInfo.value
    }

    let data = {
      "user_id": user.id || 0,
      "session_key": makalinUser.data?.access || "",
      "event": "ads_buy_product",
      "user_agent": navigator.userAgent,
      "referer": "",
      "url": window.location.href,
      "info": utmParams,
      "created": new Date().toISOString(),
    };
    localStorage.setItem('ads_url', undefined);
    localStorage.setItem('ads_date', undefined);
    store.dispatch('order/fetchSaveLog', data);
  });
      }catch(e){
        console.log(e)
      }
      

      if (!toRaw(selectedAddress?.value?.id)) {
        store.commit('snackBar/SET_DATA', {message: t('checkout.pleaseSelectAddress'), color: 'warning'})
        return
      }
      if (disabledPayButton.value) {
        store.commit('snackBar/SET_DATA', {message: t('checkout.pleaseSelectPayment'), color: 'warning'})
        return
      }
      if (selectedPayment.value === 'credit_card') {
        router.push(`/credit-card`)
        return
      }
      const body = {
        address_id: selectedAddress.value?.id,
        payment_channel: selectedPayment.value,
      }
      if (discountInfo.value) {
        body.coupon_code = discountInfo.value
      }
      body.getUser = getUser.value
      body.email = email.value
      store.dispatch('cart/fetchCreateOrder', body)
    }

    const goToSelectAddress = () => {

      if (getUser.value) {
        localStorage.setItem('userAddress', undefined)
        router.push('/my-addresses/select')
      } else {
        router.push('/my-addresses/new')
      }

    }
    const goToAddAddress = () => {
      router.push('/my-addresses/new')
    }

    const toggleShowProducts = () => {
      expanded.value = !expanded.value
    }

    onMounted(async () => {
      await store.dispatch('cart/fetchGetCart');

      await nextTick(); 

      if (!getUser.value) {
        // Handle not logged in scenario
        // Uncommenting the following line will replace the current route with the login route if the user isn't logged in.
        // store.commit('snackBar/SET_DATA', { message: t('loginFirst'), color: 'warning' });
        // router.replace('/user/login');
      } else if (!selectedAddress.value) {
        await store.dispatch('user/fetchGetAddresses');
      }

    })

    const submitReferral = async () => {
      if (refCode.value == user.value.id) {
        alert('ไม่สามารถใส่รหัสผู้แนะนำของตัวเองได้');
        return false;
      }

      const checkOrder = await userApi.checkOrder(user.value.id);
    if(checkOrder.Status == false){
      alert('รหัสผู้แนะนำสามารถใช้ได้กับลูกค้าใหม่ที่ไม่เคยใช้บริการกับทางคลินิกเท่านั้น');
      return;
    }
    try{
      const visitData = await patrickApi.getVisit(user.value.makalin_user_id);
      if(visitData.Status == false){
        alert('รหัสผู้แนะนำสามารถใช้ได้กับลูกค้าใหม่ที่ไม่เคยใช้บริการกับทางคลินิกเท่านั้น');
        return;
      }
    }catch(e){
      console.log(e)
    }

  
      try {
        const rewards = await referralApi.getUserRewards(user.value.id);
        if(rewards.length == 0){
          const checkRef = await userApi.checkRefUser(refCode.value);
          try{
            if(checkRef.name != undefined){
              alert('ผู้แนะนำของท่านคือ '+checkRef.name);
              return true;
            }
          }catch(e){
            alert('ไม่พบผู้แนะนำกรุณาลองใหม่อีกครั้ง');
          }
        }else{
          alert('ขออภัยคุณได้ใส่ผู้แนะนำแล้ว');
        }
       
      } catch (error) {
        alert('ไม่พบผู้แนะนำกรุณาลองใหม่อีกครั้ง');
      }

      return false;
    };

    return {
      t,
      backToCart,
      itemsInCart,
      email,
      total,
      grandTotal,
      shippingFee,
      discount,
      vat,
      vatPercent,
      countItem,
      paymentChannels,
      selectedPayment,
      disabledPayButton,
      clickSendByPost,
      createOrder,
      selectedAddress,
      goToSelectAddress,
      addresses,
      goToAddAddress,
      numberWithCommas,
      refCode,
      getUser,
      submitReferral,
      isDesktop,
      navItems,
      expanded,
      toggleShowProducts,
    }
  }
}
</script>

<style lang="scss" scoped>

.desktop {
  > .title {
    margin-top: 24px;
    font-size: 24px;
    text-align: center;
  }
  .product-title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    > p {
      cursor: pointer;
      font-size: 20px;
    }
    img {
      cursor: pointer;
      transition: transform 0.2s ease-in-out;
      &.active {
        transform: rotateX(180deg);
      }
    }
  }
  .desktop-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    padding: 24px 0;
    .content-wrapper {
      flex: 1;
      padding: 0;
    }
    .desktop-total-wrapper {
      background-color: #FFFFFF;
      border: 1px solid #F6F6F6;
      padding: 16px;
      border-radius: 16px;
      min-width: 352px;
      display: flex;
      flex-direction: column;
    }
  }
}

.check-out-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 56px;
  @media screen and (min-width: 780px) {
    padding-top: 0;
    padding-bottom: 0;
    height: calc(100% - 74px);
  }
}

.top-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
  }

  >button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}

.shipping-item {
  padding: 9px 16px;
  border: 1px solid #C3C3C3;
  border-radius: 4px;
  margin: 6px 0;

  .not-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 18px;
      font-weight: normal;
    }

    .go {
      font-size: 20px;
    }
  }
}

.items-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.break-line {
  border: 1px solid #D6E2EF;
  margin: 16px 0;
}

.total-content-wrapper {
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    .title {
      font-size: 18px;
      font-weight: normal;
    }

    .amount {
      font-size: 18px;
      font-weight: normal;

      &.discount {
        color: #EE3B3B;
      }

      &.grand-total {
        color: var(--success-color);
        font-size: 20px;
      }
    }
  }
}

.change-address {
  margin-left: auto;
  color: var(--secondary-color);
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 12px;
}

.payment-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.payment-item {
  padding: 9px 16px;
  border: 2px solid #C3C3C3;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;
  &.selected {
    border: 2px solid var(--success-color);
    background-color: var(--secondary-success-color);
    color: var(--success-color);
  }
}

.right-button {
  margin-right: 20px; // Adjust according to your design needs
  padding: 8px 16px; // Adds padding inside the button for better touch target
  background-color: var(--button-color, #4A58E5); // Uses a CSS variable or fallback color
  color: white; // Text color for the button
  border: none;
  border-radius: 4px; // Optional: Adds rounded corners to the button
  cursor: pointer; // Changes the cursor to a pointer when hovered over the button
}

.paragraph {
  text-align: center;
  white-space: pre-line;
  margin: 16px;
}

.middle-wrapper {
  display: flex;
  justify-content: center; // Centers the content horizontally
  align-items: center; // Centers the content vertically
  padding: 12px;
  margin-top: 20px; // Adds spacing from the top element
  border: 0.5px solid #b7b7b7;
  border-radius: 10px;

}

.referral-code-wrapper {
  display: flex;
  align-items: center; // Aligns items vertically in the center
  gap: 8px; // Adds space between the image and the text input

  .m-coin-icon {
    width: 50px;
    height: 50px;
  }

  .text-inbox {
    width: 200px;
  }
}
</style>
