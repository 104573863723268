<template>
  <div class="wrapper">
    <ImageSlider :items="bannerImages" />
    <div class="main-wrapper">
      <IntoSection />
      <HighlightSection />
      <SubHighlightSection />
      <LocationSection />
      <SubBannerSection />
      <BlogSection />
      <SubFooterSection />
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import numberWithCommas from '@/utils/numberWithCommas'
import { useI18n } from 'vue-i18n'
import { getItems } from '@/api/cms/query'
// import laserCategoryId from '@/constants/laserCategoryId'

import ImageSlider from '@/components/ImageSlider'
import LocationSection from '@/components/LocationSection'
import BlogSection from '@/components/BlogSection'
import IntoSection from '@/components/IntoSection'
import HighlightSection from '@/components/HighlightSection'
import SubHighlightSection from '@/components/SubHighlightSection'
import SubBannerSection from '@/components/SubBannerSection'
import SubFooterSection from '@/components/SubFooterSection'
// import MiniProductCard from '@/components/MiniProductCard'

export default {
  name: 'MainPage',
  components: {
    ImageSlider,
    LocationSection,
    BlogSection,
    IntoSection,
    HighlightSection,
    SubHighlightSection,
    SubBannerSection,
    SubFooterSection,
    // MiniProductCard,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    const bannerImages = ref([])
    
    /** constant */
    const end = dayjs().add(1, 'day')
    
    /** computed */
    const banners = computed(() => store.state.frontpage.data.banners)
    const recentProducts = computed(() => store.state.frontpage.data.recentProducts)
    const recommendProducts = computed(() => store.state.frontpage.data.recommendProducts)
    const categories = computed(() => store.state.frontpage.data.categories)

    /** method */
    const goToProduct = (product) => router.push(`/product/${product.id}?name=${product.name}&desc=${product.description}&price=${product.price}&image=${product.thumbnail}`)
    const goToCategory = (category) => {
      // TODO check if laser or category
      // if (category.id === laserCategoryId) {
      //   router.push(`/laser-service`)
      // }
      // else {
        router.push(`/product/category/${category.id}`)
      // }
    }

    const changeLanguage = (text) => {
      const currentLanguage = localStorage.getItem('language');
      console.log(currentLanguage)
      const wordsTH = ["เลเซอร์ และบริการอื่นๆ", "ก่อนล้างหน้า", "ล้างหน้า", "ทาสิว", "กันแดด", "บำรุงผิว", "ปรับสภาพผิว", "อื่นๆ", "แชมพู", "แก้คัน/แต้มหลุม"]
      const wordsEN = ["Laser and other services", "Before face wash", "Facial cleanser", "Acne", "Sun care", "Skin care", "Toner", "Others", "Shampoo", "Serum"]
      const wordsCN = ["激光和其他服务", "洁面之前", "洁面", "祛痘", "防晒", "护肤", "调理肌肤", "其他", "洗发水", "止痒，点痣"]
      const wordsJP = ["レーザーとその他のサービス", "洗顔前", "洗顔", "ニキビ治療", "日焼け止め", "スキンケア", "コンディショニング", "その他", "シャンプー", "かゆみ止め|スポット治療"]
      const wordsKM = ["ឡាស៊ី និងសេវាផ្សេងទៀត", "ថ្នាំផ្អាប់", "សាប៊ូលាងមុខ", "ថ្នាំលាបមុន", "ឡេការពារកំដៅថ្ងៃ", "ថ្នាំថែរក្សាស្បែក", "ថ្នាំបំប៉នស្បែក", "ផ្សេងៗ", "សាប៊ូកក់សក់", "ថ្នាំបំបាត់រមាស់ | ថ្នាំបណ្តក់ប្រហោង"]
      for (let i = 0; i < wordsTH.length; i++) {
        if (text === wordsTH[i]) {
          if (currentLanguage === 'cn') {
            return wordsCN[i]
          } else if (currentLanguage === 'jp') {
            return wordsJP[i]
          } else if (currentLanguage === 'km') {
            return wordsKM[i]
          }
        }
      }
      for (let i = 0; i < wordsEN.length; i++) {
        if (text === wordsTH[i]) {
          if (currentLanguage === 'cn') {
            return wordsCN[i]
          } else if (currentLanguage === 'jp') {
            return wordsJP[i]
          } else if (currentLanguage === 'km') {
            return wordsKM[i]
          }
        }
      }
      return text
    }

    /** mounted */
    onMounted(async () => {
      store.dispatch('frontpage/fetchFrontpageData')
      store.dispatch('user/fetchGetUserProfile')
      const bannerResponses = await getItems('main_banner')
      bannerImages.value = bannerResponses.map(banner => {
        return {
          img_url: `${process.env.VUE_APP_CMS_BASE_IMAGE_URL}/${banner.translations.image}`
        }
      })
    })

    return {
      t,
      end,
      banners,
      recentProducts,
      recommendProducts,
      goToProduct,
      goToCategory,
      categories,
      numberWithCommas,
      changeLanguage,
      bannerImages,
    }
  }
}
</script>

<style lang="scss" scoped>

.main-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  @media screen and (min-width: 780px) {
    padding: 32px 0 0;
  }
}

.products-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 24px;
  @media screen and (min-width: 780px) {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
}

.horizontal-products-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 24px;
}

.my-coin {
  margin-bottom: 16px;
}

.categories-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  margin-bottom: 16px;
  @media screen and (min-width: 780px) {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &:active {
    opacity: 0.7;
  }
  .category-title {
    margin-top: 4px;
    text-align: center;
  }
  .img-wrapper {
    width: 42px;
    height: 42px;
    background-color: #a2d1d2;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > img {
      width: 100%;
    }
  }
  @keyframes jiggle {
    0% { transform: rotate(0deg); }
    7% { transform: rotate(-5deg);}
    14% { transform: rotate(5deg) scale(1.1);}
    21% { transform: rotate(-5deg); }
    28% { transform: rotate(0deg); }
    100% { transform: rotate(0deg); }
  }

  .jiggle-animation {
      /* Applying the jiggle animation with a pause */
      animation: jiggle 3s ease-in-out infinite;
      filter: hue-rotate(-130deg) brightness(1.2);
  } 
}
</style>
