import { createApp } from 'vue'
import App from './App.vue'
import i18n from '@/i18n'
import store from '@/store'
import router from '@/router'
// import * as Vue from 'vue'
import { createHead } from '@unhead/vue'
import { vMaska } from 'maska'
import VConsole from 'vconsole'
// import * as Sentry from '@sentry/vue'
import VueGtm from 'vue-gtm'
//import VueAnalytics from 'vue-analytics'
import dayjs from 'dayjs'
import "dayjs/locale/th";
import buddhistEra from "dayjs/plugin/buddhistEra"

const app = createApp(App)
dayjs.extend(buddhistEra)
// Vue.use(VueAnalytics, {
//   id: 'G-DSSBZQK623',
//   router
// })

app.directive('click-outside', {
  beforeMount(el, binding) {
    el.clickOutsideEvent = (evt) => {
      evt.stopPropagation()
      if (!(el === evt.target || el.contains(evt.target))) {
        binding.value(evt, el)
      }
    }
    window.requestAnimationFrame(() => { document.addEventListener('click', el.clickOutsideEvent) })
  },
  unmounted(el) {
    document.removeEventListener('click', el.clickOutsideEvent)
  },
})

const head = createHead()

if (process.env.VUE_APP_API_BASE_URL.includes('pretest')) {
  const vConsole = new VConsole()
  
  console.log(vConsole.version)
}
else {
  app
    .use(VueGtm, {
      id: process.env.VUE_APP_GTM_ID,
      defer: false,
      enabled: true,
      debug: false,
      loadScript: true,
      vueRouter: router,
      trackOnNextTick: false,
    })
}

// Sentry.init({
//   app,
//   dsn: 'https://278e1143ad92e155c135eb6a59a1562e@o4505785942147072.ingest.sentry.io/4505785943326721',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         'http://localhost:8080',
//         'https://makalin.netlify.app',
//         'https://makalinconnect.com',
//       ],
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
// })

app
  .directive('maska', vMaska)
  .use(router)
  .use(store)
  .use(i18n)
  .use(head)
  .mount('#app')
