<template>
  <div class="about-us-container responsive-p">
    <NavigationBar :items="navItems" />
    <div class="content-wrapper">
      <div class="wysiwyg" v-html="content"></div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { getItem } from '@/api/cms/query'

import NavigationBar from '@/components/NavigationBar'

export default {
  name: 'TermsAndConditions',
  components: {
    NavigationBar,
  },
  setup() {
    const { t } = useI18n()

    const content = ref('')
    const title = ref('')

    onMounted(async () => {
      const termsAndConditions = await getItem('terms_and_conditions')
      if (termsAndConditions) {
        title.value = termsAndConditions.translations.title
        content.value = termsAndConditions.translations.content
      }
    })
    const navItems = computed(() => [
      {
        name: t('menu.home'),
        path: '/'
      },
      {
        name: title.value,
      }
    ])

    return {
      t,
      content,
      navItems,
    }
  }
}
</script>

<style lang="scss" scoped>
.about-us-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 56px;
  position: relative;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.content-wrapper {
  margin-top: 24px;
}

::v-deep(.wysiwyg img) {
  max-width: 100%;
  height: auto;
}
</style>
