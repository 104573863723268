<template>
  <div class="my-cart-container responsive-p desktop" v-if="isDesktop">
    <p class="title">{{ t('myCart.title') }}</p>
    <div class="desktop-wrapper" v-if="itemsInCart.length > 0">
      <div class="content-wrapper">
        <ProductInCartCard
          v-for="(item) in itemsInCart" :key="item.cart_item_id"
          :item="item"
        />
        <p class="suggest-title" v-if="suggestItemsInCart.length > 0">
          {{ t('myCart.suggestTitle') }}
        </p>
        <div class="suggest-item-wrapper" v-if="suggestItemsInCart.length > 0">
          <SuggestProductCard
            v-for="(item) in suggestItemsInCart" :key="item.id"
            :item="item"
            @click="goToProduct(item)"
          />
        </div>
        <SelectCoupon />
      </div>
      <div class="desktop-total-wrapper">
        <p>{{ t('myCart.order') }}</p>
        <div>
          <p class="total-title">{{ t('myCart.total') }} <span>({{ t('myCart.excludeShipping') }})</span></p>
          <p class="total-price">฿{{ numberWithCommas(total) }}</p>
        </div>
        <MButton blue @click="goToCheckout">{{ t('myCart.checkout') }}</MButton>
      </div>
    </div>
    <div class="no-item" v-else>
      <img src="@/assets/images/cart.svg" alt="cart">
      <p class="no-item-text">
        {{ t('myCart.noItem') }}
      </p>
      <MButton outline @click="goToMain">{{ t('myCart.shopNow') }}</MButton>
    </div>
  </div>
  <div class="my-cart-container" v-else>
    <div class="top-header-wrapper" v-if="!isDesktop">
      <p class="title">{{ t('myCart.title') }}</p>
    </div>
    <div class="content-wrapper" v-if="itemsInCart.length > 0">
      <ProductInCartCard
        v-for="(item) in itemsInCart" :key="item.cart_item_id"
        :item="item"
      />
      <p class="suggest-title" v-if="suggestItemsInCart.length > 0">
        {{ t('myCart.suggestTitle') }}
      </p>
      <div class="suggest-item-wrapper" v-if="suggestItemsInCart.length > 0">
        <SuggestProductCard
          v-for="(item) in suggestItemsInCart" :key="item.id"
          :item="item"
          @click="goToProduct(item)"
        />
      </div>
      <SelectCoupon />
      <div class="total-wrapper">
        <p class="total-title">{{ t('myCart.total') }}<br/><span>({{ t('myCart.excludeShipping') }})</span></p>
        <p class="total-price">฿{{ numberWithCommas(total) }}</p>
        <MButton success @click="goToCheckout">{{ t('myCart.checkout') }}</MButton>
      </div>
    </div>
    <div class="no-item" v-else>
      <img src="@/assets/images/cart.svg" alt="cart">
      <p class="no-item-text">
        {{ t('myCart.noItem') }}
      </p>
      <MButton outline @click="goToMain">{{ t('myCart.shopNow') }}</MButton>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import numberWithCommas from '@/utils/numberWithCommas'
import { useScreenSize } from '@/utils/isDesktop'

import MButton from '@/components/Form/MButton'
import ProductInCartCard from '@/components/ProductInCartCard'
import SuggestProductCard from '@/components/SuggestProductCard'
import SelectCoupon from '@/components/SelectCoupon'

export default {
  name: 'MyCart',
  components: {
    MButton,
    ProductInCartCard,
    SuggestProductCard,
    SelectCoupon,
},
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()
    const { isDesktop } = useScreenSize()

    /** computed */
    const itemsInCart = computed(() => store.state.cart.data?.cart_items || [])
    const suggestItemsInCart = computed(() => store.state.cart.data?.cart_suggests || [])
    const total = computed(() => store.state.cart.data?.cart_calculated?.total || [])
    const getUser = computed(() => store.getters['user/getUser'])

    /** method */
    const goToMain = () => router.push('/')
    const goToCheckout = () => router.push('/my-cart/checkout')
    const goToProduct = product => router.push(`/product/${product.id}?name=${product.name}&desc=${product.description}&price=${product.price}&image=${product.thumbnail}&b=1`)

    /** mounted */
    onMounted(() => {

      if (getUser.value) {
        store.dispatch('cart/fetchGetCart')
        store.dispatch('user/fetchGetAddresses')
        
      }
      else {
        store.dispatch('cart/fetchGetCart')
      }
    })

    return {
      t,
      itemsInCart,
      suggestItemsInCart,
      goToMain,
      total,
      goToCheckout,
      goToProduct,
      numberWithCommas,
      isDesktop,
    }
  }
}
</script>

<style lang="scss" scoped>

.desktop {
  .title {
    margin: 24px 0;
    font-size: 24px;
    text-align: center;
  }
  .desktop-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    .content-wrapper {
      flex: 1;
      overflow: hidden;
    }
    .desktop-total-wrapper {
      background-color: #FFFFFF;
      border: 1px solid #F6F6F6;
      padding: 16px;
      border-radius: 16px;
      min-width: 352px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      > div {
        color: #363636;
        display: flex;
        align-items: center;
        // background: linear-gradient(90deg, #4352E0 0%, #6876FD 100%);
        background-color: #D2D6F942;
        padding: 4px 16px 4px;
        .total-title {
          font-size: 14px;
          > span {
            font-size: 12px;
          }
        }
        .total-price {
          font-size: 24px;
          margin-left: auto;
        }
      }
    }
  }
}
.my-cart-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
  overflow-y: scroll;
  padding-top: 56px;
  @media screen and (min-width: 780px) {
    padding-top: 0;
    padding-bottom: 0;
    height: calc(100% - 74px);
  }
}

.top-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  margin-bottom: 12px;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.no-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-item-text {
  font-size: 16px;
  margin: 32px 0;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 12px 12px 136px;
  @media screen and (min-width: 780px) {
    padding: 0;
  }
}

.suggest-title {
  font-size: 20px;
  font-weight: bold;
}

.suggest-item-wrapper {
  display: flex;
  gap: 8px;
  overflow-x: auto;
}

.total-wrapper {
  background-color: #a2d1d2;
  position: fixed;
  bottom: 64px;
  left: 0;
  right: 0;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-title {
  font-size: 16px;
  text-align: center;
  > span {
    font-size: 14px;
  }
}

.total-price {
  font-size: 30px;
}
</style>
