<template>
  <div class="login-wrapper">
    <div class="login-box">
      <p class="back" @click="back" v-if="!isDesktop">&lt; {{ t('back') }}</p>
      <img src="@/assets/images/big-logo.png" alt="logo" class="logo">
      <!-- <TextInput v-model="username" :label="t('username')" />
      <TextInput v-model="password" type="password" :label="t('password')" />
      <MButton @click="login">{{ t('login') }}</MButton> -->
      <p class="welcome mt-a">{{ t('welcome') }}</p>
      <p class="describe mv-2">{{ t('logInToPurchase') }}</p>
      <MButton line @click="loginWithLine">
        <img src="@/assets/icons/white-line.svg" alt="line" class="line-logo">
        {{t('logIn')}}
      </MButton>
      <p class="other-link" @click="goToLoginWithUsername">
        {{t('LogInWithName')}}
      </p>
      <p class="other-link" @click="goToLoginWithPhone">
        {{t('LogInWithPhone')}}
      </p>
      <br/>
      <!-- <MButton blue @click="goToRegister" >
        สร้างประวัติคนไข้ใหม่
      </MButton> -->
    </div>
  </div>
</template>

<script>
import { computed, watchEffect, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useScreenSize } from '@/utils/isDesktop'
import liff from '@line/liff'

import MButton from '@/components/Form/MButton'
// import TextInput from '@/components/Form/TextInput'

export default {
  name: 'UserLogin',
  components: {
    MButton,
    // TextInput,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { t } = useI18n()
    const { isDesktop } = useScreenSize()

    /** computed */
    const getUser = computed(() => store.getters['user/getUser'])

    /** method */
    const goToLoginWithUsername = () => router.push('/user/login-with-username')
    const goToRegister = () => router.push('/user/register')
    const goToLoginWithPhone = () => router.push('/user/login-with-phone')
    const loginWithLine = () => {
      const isInClient = liff.isInClient()
      store.commit('loading/SET_DATA', { id: 'login-line', data: true })
      if (isInClient) {
        liff.init({
          liffId: process.env.VUE_APP_LINE_LIFE_ID
        }).then(() => {
          store.commit('loading/SET_DATA', { id: 'login-line', data: false })
          const body = {
            lineAccessToken: liff.getAccessToken()
          }
          store.dispatch('user/fetchLoginWithLine', body)
        })
      }
      else {
        liff.init({
          liffId: process.env.VUE_APP_LINE_LIFE_ID
        }).then(() => {
          store.commit('loading/SET_DATA', { id: 'login-line', data: false })
          if (!liff.isLoggedIn()) {
            const destinationUrl = `${window.location.href}?loggedIn=1`
            liff.login({ redirectUri: destinationUrl })
          }
          else {
            const body = {
              lineAccessToken: liff.getAccessToken()
            }
            store.dispatch('user/fetchLoginWithLine', body)
          }
        })
      }
      
    }
    const back = () => {
      router.back()
    }

    /** mounted */
    onMounted(() => {
      const isInClient = liff.isInClient()
      const s = route.query.s
      const loggedIn = route.query.loggedIn
      const path = route.query?.path
      if (path) {
        sessionStorage.setItem('redirectTo', `/${path}`)
        loginWithLine()
      }
      if (isInClient && s) {
        store.commit('loading/SET_DATA', { id: 'login-line', data: true })
        liff.init({
          liffId: process.env.VUE_APP_LINE_LIFE_ID
        }).then(() => {
          store.commit('loading/SET_DATA', { id: 'login-line', data: false })
          store.dispatch('user/fetchLoginWithLine', {
            lineAccessToken: liff.getAccessToken(),
            s,
          })
        })
      }
      else if (!isInClient) {
        liff.init({
          liffId: process.env.VUE_APP_LINE_LIFE_ID
        }).then(() => {
          store.commit('loading/SET_DATA', { id: 'login-line', data: true })
          
          const body = {
            lineAccessToken: liff.getAccessToken()
          }
          if (s) {
            body.s = s
            if (!liff.isLoggedIn()) {
              const destinationUrl = window.location.href
              liff.login({ redirectUri: destinationUrl })
            }
            if (liff.isLoggedIn()) {
              store.commit('loading/SET_DATA', { id: 'login-line', data: false })
              store.dispatch('user/fetchLoginWithLine', body)
            }
          }
          else {
            store.commit('loading/SET_DATA', { id: 'login-line', data: false })
            if (loggedIn) {
              store.dispatch('user/fetchLoginWithLine', body)
            }
          }
        })
      }
    })

    /** watch */
    watchEffect(() => {
      if (getUser.value) {
        const s = route.query.s
        if (s) {
          store.dispatch('user/fetchGetUserProfile', '/my-cart')
        }
        else {
          store.dispatch('user/fetchGetUserProfile', '/my-profile')
        }
      }
    })
    return {
      t,
      goToLoginWithUsername,
      goToLoginWithPhone,
      loginWithLine,
      getUser,
      back,
      goToRegister,
      isDesktop
    }
  }
}
</script>

<style lang="scss" scoped>

.login-wrapper {
  height: 100%;
  @media screen and (min-width: 780px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 74px);
  }
}

.login-box {
  background-color: var(--background-color);
  background-image: url('@/assets/images/bg-logo.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 28px 60px;
  @media screen and (min-width: 780px) {
    height: auto;
    width: 100%;
    min-width: 500px;
    border-radius: 8px;
    padding: 24px 28px;
  }
}

button {
  width: 100%;
}

.other-link {
  text-decoration: underline;
  cursor: pointer;
  color: var(--secondary-color);
  text-align: center;
  margin-top: 12px;
  &:first-child {
    margin-top: 24px;
  }
}

.logo {
  margin: 0 auto;
}

.line-logo {
  margin-right: 12px;
}

.welcome {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.describe {
  font-size: 18px;
  text-align: center;
}

.back {
  color: var(--secondary-color);
  margin-bottom: 12px;
  font-size: 1.2rem;
  padding: 12px;
  cursor: pointer;
}

</style>
