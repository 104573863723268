<template>
  <div class="input-wrapper">
    <FilterModal v-model="showFilter" :callback="fetchProducts" />
    <img src="@/assets/icons/search.svg" alt="search">
    <input type="text" :placeholder="t('search')" v-model="searchString">
    <img src="@/assets/icons/filter-black.svg" alt="filter" @click="showFilter = true">
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import useDebounceRef from '@/composables/useDebounceRef'

import FilterModal from '@/components/FilterModal'

export default {
  components: {
    FilterModal,
  },
  props: {
    modelValue: {
      require: true,
    },
    hideFilter: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { t } = useI18n()
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    
    const showFilter = ref(false)
    const searchString = useDebounceRef('', 500)

    const priceFrom = computed(() => store.state.filter.data.priceFrom)
    const priceTo = computed(() => store.state.filter.data.priceTo)
    const sorting = computed(() => store.state.filter.data.sorting)
    const selectedCategories = computed(() => store.state.filter.data.categories)

    onMounted(() => {
      searchString.value = route.query.searchString
    })

    const fetchProducts = (newQuery = searchString.value) => {
      router.push(`/products/search?searchString=${newQuery}`)
      const q = newQuery ? newQuery : searchString.value
      const queryBody = {
        query: q,
        price_from: priceFrom.value,
        price_to: priceTo.value,
        sorting: sorting.value,
        category: selectedCategories.value.join(','),
      }
      store.dispatch('products/fetchGetProductsByKeywords', queryBody)
    }

    const search = () => {
    }

    watch(searchString, newQuery => {
      fetchProducts(newQuery)
    })

    return {
      t,
      showFilter,
      searchString,
      fetchProducts,
      search
    }
  }
}
</script>

<style lang="scss" scoped>

.input-wrapper {
  background-color: var(--white-color);
  border: 1px solid var(--secondary-color);
  padding: 8px 20px;
  border-radius: 38px;
  display: flex;
  @media screen and (min-width: 780px) {
    border: 1px solid var(--white-grey-color);
    > img {
      cursor: pointer;
    }
  }
}

input {
  border: 0;
  outline: none;
  margin-left: 8px;
  &::-webkit-input-placeholder {
    color: #CCCCCC;
  }

  &:-ms-input-placeholder {
    color: #CCCCCC;
  }

  &::placeholder {
    color: #CCCCCC;
  }
}
</style>
