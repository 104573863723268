<template>
  <div class="credit-card-container responsive-p">
    <div class="top-header-wrapper">
      <button @click="back" v-if="!isDesktop">
        <img src="@/assets/icons/primary-back.svg" alt="back">
      </button>
      <p class="title">{{ t('creditCard.title') }}</p>
    </div>
    <form @submit="submit">
      <TextInput
        :label="`${t('creditCard.label.cardNumber')}:`"
        v-model="cardNumber"
        name="cardNumber"
        required
        :isNumber="true"
        :placeholder="t('creditCard.placeholder.cardNumber')"
        mask="#### #### #### ####"
        type="tel"
      />
      <TextInput
        :label="`${t('creditCard.label.nameOnCard')}:`"
        v-model="nameOnCard"
        name="nameOnCard"
        :placeholder="t('creditCard.placeholder.nameOnCard')"
        required
      />
      <div class="row">
        <TextInput
          class="col"
          :label="`${t('creditCard.label.expired')}:`"
          v-model="expired"
          name="expired"
          required
          :isNumber="true"
          :placeholder="t('creditCard.placeholder.exp')"
          mask="##/##"
        />
        <TextInput
          class="col"
          :label="`${t('creditCard.label.cvv')}:`"
          :placeholder="t('creditCard.placeholder.cvv')"
          v-model="cvv"
          name="cvv"
          required
          :isNumber="true"
          mask="###"
          type="tel"
        />

      </div>
      <MButton type="submit" :full-width="!isDesktop" :class="`${!isDesktop ? 'mt-a' : 'mt-2'}`">
        {{ t('creditCard.continue') }}
      </MButton>
    </form>
  </div>
</template>


<script>
import { onMounted, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useScreenSize } from '@/utils/isDesktop'

import TextInput from '@/components/Form/TextInput'
import MButton from '@/components/Form/MButton'

export default {
  name: 'CreditCard',
  components: {
    TextInput,
    MButton,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()
    const cardNumber = ref(null)
    const nameOnCard = ref(null)
    const expired = ref(null)
    const cvv = ref(null)
    const { isDesktop } = useScreenSize()
    
    const selectedAddress = computed(() => store.getters['user/getSelectedAddress'])

    const back = () => {
      router.replace('/my-cart/checkout')
    }

    const submit = (e) => {
      e.preventDefault()
      const body = {
        rememberCard: false,
        card: {
          number: cardNumber.value.replaceAll(' ', ''),
          expirationMonth: expired.value.split('/')[0],
          expirationYear: expired.value.split('/')[1],
          securityCode: cvv.value,
          name: nameOnCard.value,
        }
      }

      if (!body.card.name) {
        return store.commit('snackBar/SET_DATA', { message: t('creditCard.errs.nameOnCard'), color: 'error' }, { root: true })
      }
      if (`${body.card.number}`.length !== 16) {
        return store.commit('snackBar/SET_DATA', { message: t('creditCard.errs.length'), color: 'error' }, { root: true })
      }
      if (`${expired.value}`.length !== 5) {
        return store.commit('snackBar/SET_DATA', { message: t('creditCard.errs.expFormat'), color: 'error' }, { root: true })
      }
      if (parseInt(body.card.expirationMonth) > 12) {
        return store.commit('snackBar/SET_DATA', { message: t('creditCard.errs.month'), color: 'error' }, { root: true })
      }
      if (`${body.card.securityCode}`.length !== 3) {
        return store.commit('snackBar/SET_DATA', { message: t('creditCard.errs.cvv'), color: 'error' }, { root: true })
      }
      store.dispatch('cart/fetchGetGbPayToken', body)
    }

    onMounted(() => {
    })

    return {
      t,
      cardNumber,
      nameOnCard,
      expired,
      cvv,
      selectedAddress,
      submit,
      back,
      isDesktop,
    }
  }
}
</script>


<style lang="scss" scoped>
.credit-card-container {
  height: 100%;
  padding: 70px 16px 16px;
  @media screen and (min-width: 780px) {
    padding: 0;
    height: calc(100% - 74px);
  }
  > form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.back {
  color: var(--secondary-color);
  margin-bottom: 12px;
  font-size: 1.2rem;
  padding: 12px;
  cursor: pointer;
}

.top-header-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  background-color: var(--white-color);
  @media screen and (min-width: 780px) {
    position: relative;
    border-bottom: 0;
  }
  > button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
  }
}
</style>

