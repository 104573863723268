<template>
  <div class="my-addresses-container responsive-p">
    <NavigationBar :items="navItems" />
    <div class="top-header-wrapper">
      <button @click="back" v-if="!isDesktop">
        <img src="@/assets/icons/primary-back.svg" alt="back">
      </button>
      <p class="title">{{ t('myAddresses.title') }}</p>
      <button @click="goToAddAddress" class="add">
        <img src="@/assets/icons/primary-add.svg" alt="add">
      </button>
    </div>
    <div class="no-item-wrapper" v-if="addresses.length === 0">
      <img src="@/assets/images/address.svg" alt="address">
      <p class="no-item-text">
        {{  t('myAddresses.noItem') }}
      </p>
    </div>
    <div class="items-wrapper" v-else-if="addresses.length > 0">
      <AddressCard
        v-for="address in addresses"
        :key="address.id"
        :address="address"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useScreenSize } from '@/utils/isDesktop'

import AddressCard from '@/components/AddressCard'
import NavigationBar from '@/components/NavigationBar'

export default {
  name: 'MyAddressView',
  components: {
    AddressCard,
    NavigationBar,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()
    const { isDesktop } = useScreenSize()

    /** computed */
    const addresses = computed(() => store.state.user.addresses || [])
    const navItems = computed(() => [
      {
        name: t('menu.home'),
        path: '/'
      },
      {
        name: t('menu.profile'),
        path: '/my-profile'
      },
      {
        name: t('profileMenu.address')
      }
    ])
    
    /** method */
    const back = () => router.back()
    const goToAddAddress = () => router.push('/my-addresses/new')

    /** mounted */
    onMounted(() => {
      store.dispatch('user/fetchGetAddresses')
    })

    return {
      t,
      back,
      goToAddAddress,
      addresses,
      isDesktop,
      navItems,
    }
  }
}
</script>

<style lang="scss" scoped>

.my-addresses-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 56px;
  @media screen and (min-width: 780px) {
    padding-top: 0;
    padding-bottom: 0;
    height: calc(100% - 74px);
    margin-top: 24px;
  }
}

.top-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  @media screen and (min-width: 780px) {
    position: relative;
    border-bottom: 0;
  }
  > button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 12px;
    &.add {
      left: auto;
      right: 10px;
      font-size: 30px;
    }
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.desktop-title {
  font-size: 24px;
  font-weight: bold;
}

.no-item-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.items-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.no-item-text {
  font-size: 20px;
  margin-top: 16px;
}
</style>
