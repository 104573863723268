<template>
  <div class="coupon-container">
    <div class="card">
      <div class="top-header-wrapper">
        <button @click="close">
          <img src="@/assets/icons/primary-back.svg" alt="back">
        </button>
        <p class="title">{{ t('myCoupon.title') }}</p>
      </div>
      <div v-if="coupons.length === 0" class="no-coupon-wrapper">
        <img src="@/assets/images/no-coupon.svg" alt="no-coupon">
        <p class="no-coupon-text">{{ t('myCoupon.noItem') }}</p>
      </div>
      <div v-else-if="coupons.length > 0" class="coupon-wrapper">
        <CouponCard
          v-for="coupon in coupons"
          :key="coupon.id"
          :coupon="coupon"
        />
        <MButton :disabled="!selectedCoupon" class="abs-b" @click="close">{{  t('submit') }}</MButton>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import CouponCard from '@/components/CouponCard'
import MButton from '@/components/Form/MButton'

export default {
  name: 'CouponView',
  props: {
    back: {
      type: Function,
    }
  },
  components: {
    CouponCard,
    MButton,
  },
  setup(props) {
    /** context */
    const store = useStore()
    const { t } = useI18n()

    /** computed */
    const coupons = computed(() => store.state.cart.data?.coupon_promotions || [])
    const selectedCoupon = computed(() =>  store.state.cart.data.coupon_code)

    /** method */
    const close = () => props.back()

    return {
      t,
      coupons,
      selectedCoupon,
      close
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 24px;
  font-weight: bold;
}
.coupon-container {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #CFCFCFCC;
}

.card {
  background-color: #FFFFFF;
  min-width: 500px;
  padding: 16px;
  border-radius: 24px;
}

.top-header-wrapper {
  display: flex;
  align-items: flex-end;
  padding: 16px;
  background-color: var(--white-color);
  gap: 16px;
  > button {
    font-weight: bold;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
  }
}

.coupon-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 16px;
  padding-bottom: 64px;
  gap: 16px;
}

.no-coupon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.no-coupon-text {
  font-size: 16px;
  margin: 32px 0;
}

.abs-b {
  position: fixed;
  bottom: 12px;
  left: 12px;
  right: 12px;
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 700px;
  }
}

</style>
