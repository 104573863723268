<template>
  <div class="login-wrapper responsive-p">
    <div class="login-box">
      <p class="back" @click="backToLogin" v-if="!isDesktop">&lt; {{ t('back') }}</p>
      <p class="title">{{ t('loginWithUsername.title') }}</p>
      <div class="login-with-username">
        <TextInput v-model="username" :label="t('username')" />
        <TextInput v-model="password" type="password" :label="t('password')" />
        <MButton @click="login">{{ t('login') }}</MButton>
        <p class="forget-password" @click="goToForgetPassword">{{ t('forgetPassword.title') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useScreenSize } from '@/utils/isDesktop'

import MButton from '@/components/Form/MButton'
import TextInput from '@/components/Form/TextInput'

export default {
  name: 'UserLogin',
  components: {
    MButton,
    TextInput,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()
    const { isDesktop } = useScreenSize()

    /** reactive */
    const username = ref('')
    const password = ref('')

    /** computed */
    const getUser = computed(() => store.getters['user/getUser'])

    /** method */
    const login = () => {
      store.dispatch('user/fetchLoginWithUsername', {
        username: username.value,
        password: password.value,
      })
    }
    const backToLogin = () => {
      router.replace('/user/login')
    }
    const goToForgetPassword = () => {
      router.push('/user/forget-password')
    }
    
    /** watch */
    watchEffect(() => {
      if (getUser.value) {
        store.dispatch('user/fetchGetUserProfile', '/my-profile')
      }
    })

    return {
      t,
      login,
      backToLogin,
      username,
      password,
      goToForgetPassword,
      isDesktop
    }
  }
}
</script>

<style lang="scss" scoped>

.login-wrapper {
  height: 100%;
  @media screen and (min-width: 780px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 74px);
  }
}

.login-box {
  background-color: var(--background-color);
  background-image: url('@/assets/images/bg-logo.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 28px 60px;
  @media screen and (min-width: 780px) {
    height: auto;
    width: 100%;
    min-width: 500px;
    border-radius: 8px;
    padding: 24px 28px;
  }
}

.log-in-with-username {
  padding: 0 40px;
}

button {
  width: 100%;
}

.back {
  color: var(--secondary-color);
  margin-bottom: 12px;
  font-size: 1.2rem;
  padding: 12px;
  cursor: pointer;
}

.title {
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 24px;
  color: var(--secondary-color);
}

.description {
  color: var(--secondary-color);
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 24px;
}

.forget-password {
  background-color: transparent;
  color: var(--secondary-color);
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 16px;
  text-align: right;
  &:active {
    opacity: 0.8;
  }
}
</style>
