<template>
  <div class="my-drugs-container responsive-p">
    <p class="title mb-2">{{ t('myDiaryDrugs.title') }}</p>
    <!-- <DrugGraph
      class="mb-2"
    /> -->
    <ContinueDrugs class="mb-2" />
    <div class="menus-wrapper">
      <img src="@/assets/images/latest-receive.png" alt="menu-image" class="menu-image touchable" @click="goTo('/latest-receive')">
      <img src="@/assets/images/latest-purchase.png" alt="menu-image" class="menu-image touchable" @click="goTo('/latest-purchase')">
      <img src="@/assets/images/recommend.png" alt="menu-image" class="menu-image touchable" @click="goTo('/recommend')">
      <img src="@/assets/images/mostly-purchase.png" alt="menu-image" class="menu-image touchable" @click="goTo('/frequency')">
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

// import DrugGraph from '@/components/DrugGraph'
import ContinueDrugs from '@/components/ContinueDrugs'

export default {
  name: 'myDrugs',
  components: {
    // DrugGraph,
    ContinueDrugs,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    /** method */
    const goTo = path => router.push(`/my-drugs${path}`)

    /** mounted */
    onMounted(() => {
      store.dispatch('user/fetchMyContinueDrugs')
    })

    return {
      t,
      goTo,
    }
  }
}
</script>

<style lang="scss" scoped>

.my-drugs-container {
  padding: 16px;
  padding-bottom: 72px;
  overflow-y: scroll;
  @media screen and (min-width: 780px) {
    padding-top: 16px;
    padding-bottom: 16px;
    height: calc(100% - 74px);
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.menus-wrapper {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
}

.menu-image {
  min-width: 160px;
  max-width: 180px;
  cursor: pointer;
}
</style>
