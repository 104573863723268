<template>
  <div class="promotions-wrapper responsive-p">
    <!-- <BackTopBar /> -->
    <NavigationBar :items="navItems" />
    <div class="content-wrapper main">
      <p class="title">
        {{ title }}
      </p>
    </div>
    <div class="promotion-items-wrapper">
      <div
        class="promotion-item"
        v-for="(item, index) in items"
        :key="index"
      >
        <div class="promotion-image" @click="goToPromotion(item)">
          <img :src="item.image" :alt="item.title">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { getItem, getItems } from '@/api/cms/query'

import NavigationBar from '@/components/NavigationBar'
// import BackTopBar from '@/components/BackTopBar.vue'

defineComponent({
  name: 'AllPromotion'
})

const router = useRouter()

const title = ref('')
const items = ref([])

onMounted(async () => {
  const promotions = await getItems('promotions', 100, {}, '&filter[start_date_time][_lte]=$NOW&filter[end_date_time][_gte]=$NOW')
  const promotionHeader = await getItem('promotion_header')
  if (promotionHeader) {
    title.value = promotionHeader.translations.title
  }
  items.value = promotions.map(promotion => {
    return {
      title: promotion.translations.name,
      image: `${process.env.VUE_APP_CMS_BASE_IMAGE_URL}/${promotion.translations.image}`,
      content: promotion.translations.content,
      id: promotion.id
    }
  })
})

const { t } = useI18n()

const navItems = computed(() => [
  {
    name: t('menu.home'),
    path: '/'
  },
  {
    name: title?.value,
  }
])

const goToPromotion = item => {
  router.push(`/promotions/${item.id}`)
}
</script>

<style lang="scss" scoped>
.promotions-wrapper {
  padding: 24px;
  margin-top: 64px;
  @media screen and (min-width: 780px) {
    margin: 24px;
  }
}

.promotion-items-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 48px;
  align-items: center;
}

.promotion-item {
  display: flex;
  gap: 0px;
  flex-direction: column;
  @media screen and (min-width: 780px) {
    flex-direction: row;
    gap: 48px;
  }
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  @media screen and (min-width: 780px) {
    padding: 16px 0;
  }
  &.main {
    margin-bottom: 24px;
  }
  .title {
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.promotion-image {
  flex: 1;
  border-radius: 24px;
  overflow: hidden;
  object-fit: cover;
  > img {
    width: auto;
    max-width: 100%;
    max-height: 80vh;
    cursor: pointer;
  }
}

.created-at {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  > a {
    text-decoration: underline;
    color: var(--secondary-color);
    cursor: pointer;
  }
}
</style>
