<template>
  <div class="promotion-wrapper responsive-p" v-if="item">
    <!-- <BackTopBar /> -->
    <NavigationBar :items="navItems" />
    <p class="title">
      {{ item.title }}
    </p>
    <img :src="item.image" alt="" class="promotion-image">
    <div class="content-wrapper">
      <div class="detail-wrapper">
        <p class="created-by">{{ item.startDate }} - {{ item.endDate }}</p>
        <div class="wysiwyg" v-html="item.content" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { getItem, getItemById } from '@/api/cms/query'

import NavigationBar from '@/components/NavigationBar'
// import BackTopBar from '@/components/BackTopBar'
import dayjs from 'dayjs'

defineComponent({
  name: 'PromotionById'
})

const route = useRoute()
const { t, locale } = useI18n()

const item = ref({})
const title = ref('')

const navItems = computed(() => [
  {
    name: t('menu.home'),
    path: '/'
  },
  {
    name: title?.value,
    path: '/promotions'
  },
  {
    name: item?.value?.title,
  }
])

onMounted(async () => {
  const id = route.params.id
  const promotionHeader = await getItem('promotion_header')
  const promotion = await getItemById('promotions', id)
  const formatString = locale.value === 'th' ? 'D MMMM BBBB' : 'D MMMM YYYY'
  title.value = promotionHeader.translations.title
  item.value = {
    title: promotion.translations.name,
    image: `${process.env.VUE_APP_CMS_BASE_IMAGE_URL}/${promotion.translations.image}`,
    content: promotion.translations.content,
    startDate: dayjs(promotion.start_date_time).locale(locale.value).format(formatString),
    endDate: dayjs(promotion.end_date_time).locale(locale.value).format(formatString),
  }
})
</script>

<style lang="scss" scoped>

.promotion-wrapper {
  padding: 24px;
  margin-top: 64px;
  @media screen and (min-width: 780px) {
    margin: 24px;
  }
}
.promotion-image {
  display: block;
  margin: 24px auto;
  width: 100%;
  max-width: 920px;
}

.title {
  font-size: 1.8rem;
  font-weight: bold;
}
.description {
  font-size: 1.4rem;
  text-align: center;
}

.detail-wrapper {
  flex: 1;
}
.created-by {
  text-align: right;
}

::v-deep(.wysiwyg img) {
  max-width: 100%;
  height: auto;
}
</style>
