export default {
  namespaced: true,
  state: {
    data: []
  },
  mutations: {
    SET_DATA(state, { id, data }) {
      if (data) {
        state.data = [ ...state.data, id ]
      }
      else {
        state.data = state.data.filter(d => d !== id)
      }
    },
    RESET(state) {
      state.data = []
    }
  },
  getters: {
    isLoading: state => state.data.length > 0
  }
}
