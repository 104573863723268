export default {
  "makalin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["马可琳"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎光临"])},
  "logInToPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请登录以购买商品...."])},
  "LogInWithName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用用户名登录"])},
  "LogInWithPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用电话号码登录"])},
  "notFoundProduct": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["售出 ", _interpolate(_list(0)), " 件"])},
  "addfriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加好友"])},
  "th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往商店"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
  "soldAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "goToStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改头像"])},
  "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男性"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女性"])},
  "changeProfileImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取 OTP"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统中未找到此电话号码"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "getOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "notFoundPhoneInSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或"])},
  "pleaseContactAddmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置新密码"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认新密码"])},
  "setNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请先登录"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新资料成功"])},
  "reNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改密码成功"])},
  "loginFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分店"])},
  "successUpdateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确定"])},
  "successUpdatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确定"])},
  "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回首页"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泰铢"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
  "backToMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未收到 OTP？"])},
  "baht": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("second")), " 秒"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击此处重新发送 OTP"])},
  "didntGetOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证电话号码以获得 100 枚金币"])},
  "otpCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累积金币可作为商品折扣"])},
  "resendOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在此处搜索..."])},
  "goToVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请稍候"])},
  "collectCoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阅读更多"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功复制到剪贴板"])},
  "moreDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击此处下载二维码"])},
  "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果未转到付款页面或尚未完成操作，请点击此处"])},
  "copyToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出现了一些问题"])},
  "downloadQrCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
  "clickToPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果未跳转到支付页面，请点击此处或再次检查是否已安装SCB应用程序。"])},
  "somethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的药品"])},
  "recentlyAddedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently Added"])},
  "recommendedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended for You"])},
  "relateProductTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สินค้าที่เกี่ยวข้อง"])},
  "footer": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกี่ยวกับเรา"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])}
  },
  "topBar": {
    "language": {
      "cn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จีน"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อังกฤษ"])},
      "jp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ญี่ปุ่น"])},
      "km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เขมร"])},
      "th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไทย"])}
    }
  },
  "location": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about Makalin Clinic locations or choose a specific location."])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore all locations"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โทร"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขอเส้นทาง"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียด"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request an Appointment"])}
  },
  "blog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Featured care areas"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We solve the world's most serious and complex medical challenges."])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore all blogs"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เขียนโดย"])},
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อ่าน"])},
    "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียงโดย"])},
    "old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก่า"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใหม่"])}
  },
  "aboutUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])}
  },
  "session": {
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["药品形式"])}
  },
  "myContinueDrugs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包装规格"])},
    "howToUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看药品信息"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您还没有药品清单"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上午"])},
    "seeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中午"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["晚上"])},
    "morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["睡前"])},
    "afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "evening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在使用的药品"])},
    "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已用完的药品"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择将药品清单调整为“已用完的药品”"])},
    "usingDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择将药品清单调整为“正在使用的药品”"])},
    "unusedDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
    "setUnusedDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓氏"])},
    "setUsingDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示名称"])}
  },
  "profile": {
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
    "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生日"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性别"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["体重 (公斤)"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身高 (厘米)"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑个人资料"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入电子邮件"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入生日"])},
    "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入身高"])},
    "pleaseFillEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入体重"])},
    "pleaseFillBoD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "pleaseFillHegiht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["药物过敏"])},
    "pleaseFillWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无数据"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注明您过敏的药物"])},
    "allergicDrug": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["慢性疾病"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无数据"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注明您的慢性疾病"])}
    },
    "congenitalDisease": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长期服用的药物"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无数据"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注明您长期服用的药物"])}
    },
    "personalMedicine": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在内付款"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私政策"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已知悉并同意"])}
    }
  },
  "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用信用卡/借记卡付款"])},
  "term": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月/年"])}
  },
  "creditCard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡上姓名"])},
    "placeholder": {
      "exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XXXX XXXX XXXX XXXX"])},
      "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡号"])},
      "nameOnCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡上姓名"])},
      "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡有效期"])}
    },
    "label": {
      "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
      "nameOnCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡号必须为 16 位数字"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡有效期必须填写为月/年"])},
      "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月份不正确"])}
    },
    "errs": {
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年份不正确"])},
      "expFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV 必须为 3 位数字"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出现了一些问题"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入卡上姓名"])},
      "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的信用卡已过期"])},
      "somethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡信息不正确"])},
      "nameOnCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消订单"])},
      "cardExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择取消订单的原因"])},
      "cardInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券"])}
    }
  },
  "cancellation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您还没有优惠券"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用优惠券"])}
  },
  "myCoupon": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择优惠券"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折扣"])},
    "useCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无最低消费"])},
    "selectCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低消费"])},
    "card": {
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效期至"])},
      "noMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无到期日"])},
      "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏夹"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已添加商品至收藏夹"])},
      "noExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已移除商品至收藏夹"])}
    }
  },
  "myFavorite": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证电话号码"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入电话号码"])},
    "successRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以进行身份验证"])}
  },
  "verifyUser": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费获得"])},
    "pleaseFillPhone": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 枚金币"])},
    "forVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入 OTP"])},
    "getFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用电话号码登录"])},
    "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入电话号码（已连接到 Makalin Clinic）"])},
    "pleaseFillOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以进行身份验证"])}
  },
  "loginWithPhone": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入 OTP"])},
    "pleaseFillPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码"])},
    "forVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入电话号码（已连接到 Makalin Clinic）"])},
    "pleaseFillOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以进行身份验证"])}
  },
  "forgetPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入 OTP"])},
    "pleaseFillPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用用户名登录"])},
    "forVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页"])},
    "pleaseFillOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的药品"])}
  },
  "loginWithUsername": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物车"])}
  },
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人资料"])},
    "myDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的购物"])},
    "myCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请好友累积积分"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长期用药"])}
  },
  "profileMenu": {
    "myHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理地址"])},
    "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行账户信息"])},
    "myDrugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助中心"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我"])},
    "bankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏夹"])},
    "helpService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的常用药"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常用药数量"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持续使用的前 10 种药品"])}
  },
  "myDiaryDrugs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您还没有药品清单"])},
    "drugGraphTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "continueDrugsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐商品"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经常购买的商品"])},
    "seeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近购买的商品"])},
    "recommendTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将所有商品添加到购物车"])},
    "frequencyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全选"])},
    "latestPurchaseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部移除"])},
    "addAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将所选商品添加到购物车"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的购物车"])},
    "removeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的购物车中还没有商品"])},
    "addSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即选购"])}
  },
  "myCart": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一起购买更优惠"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已将商品添加到购物车"])},
    "shopNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品总价"])},
    "suggestTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未包含运费"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订购商品"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单摘要"])},
    "excludeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送方式"])},
    "shortTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单摘要"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])}
  },
  "checkout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮寄配送"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到店自取"])},
    "sendByPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品清单"])},
    "takeaway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品价格（含 7% 增值税）"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运费"])},
    "sumTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券折扣"])},
    "shippingFee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["增值税 ", _interpolate(_named("percent")), "%"])},
    "sumDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
    "sumVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])},
    "sumGrandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的电子邮件"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
    "emailDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入邀请码"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“我已接受退款和商品政策"])},
    "referalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（点击以阅读更多详细信息）"])},
    "placeHolderReferalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系电话：064-181-6580-2”"])},
    "acceptParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改送货地址"])},
    "changeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往添加地址"])},
    "goToAddAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请添加送货地址"])},
    "pleaseSelectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择付款方式"])},
    "pleaseSelectPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改送货地址"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Code"])}
  },
  "myAddresses": {
    "changeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址簿"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您尚未保存地址"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要地址"])},
    "mainAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功创建新地址"])},
    "successAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功编辑地址"])},
    "successUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功删除地址"])},
    "successDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加地址"])}
  },
  "addAddresses": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑地址"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国内"])},
    "inCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国外"])},
    "abroad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人姓名"])},
    "form": {
      "receiveName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址类型"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址详细信息（门牌号、巷弄、街道等）"])},
      "addressNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["省份"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区/县"])},
      "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["乡/镇"])},
      "subDistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮政编码"])},
      "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设为主要地址"])},
      "setMainAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳过"])},
      "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家庭"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有商品"])}
    }
  },
  "allProducts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请好友累积积分"])}
  },
  "referral": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请码"])},
    "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的积分"])},
    "myscore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好友数量"])},
    "friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入邀请码"])},
    "inputRefCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累积积分历史记录"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请好友累积积分"])},
    "useCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已获得好友输入您的邀请码的积分"])},
    "useQRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扫描以积分"])},
    "useCodeDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["积分"])},
    "useQRCodeDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已通过扫描QR码获得积分"])},
    "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出现了一些问题"])}
  },
  "order": {
    "somethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功付款"])},
    "successPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款失败，请重新付款"])},
    "errorPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款失败，请重新操作"])},
    "creditCardErrorPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再次购买"])},
    "orderAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单清单"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单 ID"])},
    "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单"])},
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品总价"])},
    "sumTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运费"])},
    "shippingFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券折扣"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单总计"])},
    "grandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您将获得累积积分"])},
    "gainPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮寄配送"])},
    "sendByPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包裹追踪号"])},
    "parcelTrackNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品清单"])},
    "itemTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["件"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消订单"])},
    "cancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回主页"])},
    "backHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚无包裹追踪号"])},
    "noParcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“请完成操作"])},
    "qrDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["然后点击下方关闭”"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
    "successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功取消订单"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法取消订单"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请联系管理员以退款"])},
    "statuses": {
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已付款"])},
      "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发货"])},
      "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到商品"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已取消"])}
    }
  },
  "latestReceive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["医生最近开的药"])},
    "addAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将所有商品添加到购物车"])}
  },
  "filter": {
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按类别搜索"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除"])},
    "priceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格范围"])},
    "priceRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格范围（泰铢）"])},
    "inputLowestPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入最低价格"])},
    "inputHighestPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入最高价格"])},
    "priceFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从"])},
    "priceTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选器"])},
    "sortLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序方式"])},
    "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细搜索"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折扣"])},
    "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销量"])},
    "numVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浏览量"])},
    "tab": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折扣"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
      "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["畅销商品"])},
      "numVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浏览量"])}
    }
  },
  "product": {
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询"])},
    "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入购物车"])},
    "buyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即购买"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])}
  },
  "laserService": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["激光服务"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约"])},
    "reserverTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约人信息"])},
    "packageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["套餐"])},
    "reserverName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名 - 姓氏"])},
    "reserverPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
    "getOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取 OTP"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约医生"])},
    "fillOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入 OTP"])},
    "sendOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统已向电话号码发送短信 OTP"])},
    "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约成功"])},
    "successDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已成功预约医生"])},
    "memberTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请验证您的电话号码"])},
    "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往登录页面"])},
    "connectMakalin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往验证电话号码页面"])},
    "successConnectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功关联历史记录"])},
    "successConnectDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恭喜您获得 100 枚金币"])},
    "backToReserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回预约页面"])}
  }
}