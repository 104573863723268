<template>
  <div class="footer-wrapper">
    <div class="max-width-wrapper">
      <div class="link-wrapper">
        <a
          :href="item.path"
          v-for="(item, index) in items"
          :key="index"
          :class="{'is-head' : item.isHead}"
        >
          {{ item.title }} {{ item.isHead ? '>' : '' }}
        </a>
      </div>
      <div class="line" />
      <div class="social-wrapper">
        <img
          :src="social.icon"
          v-for="(social, index) in socials"
          :key="index"
          @click="goTo(social)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { getItems } from '@/api/cms/query'

defineComponent({
  name: 'FooterLanding',
})

const { t } = useI18n()

const socials = ref([])

onMounted(async () => {
  const socialLinks = await getItems('social_links')
  socials.value = socialLinks.map(socialLink => ({
    ...socialLink,
    icon: `${process.env.VUE_APP_CMS_BASE_IMAGE_URL}/${socialLink.icon}`
  }))
  console.log('socialLinks', socialLinks)
})

const items = ref([
  { title: t('footer.about'), path: '/about-us', isHead: true },
  { title: t('footer.privacy'), path: '/privacy-policy' },
  // { title: t('footer.terms'), path: '/terms-and-conditions' },
])

const goTo = (social) => {
  window.open(social.link, '_blank')
}
</script>

<style lang="scss" scoped>

.max-width-wrapper {
  padding: 12px 16px;
  margin-top: auto;
  width: 100%;
  max-width: 1440px;
  @media screen and (min-width: 780px) {
    padding: 24px 72px;
    margin-bottom: 0;
  }
}
.footer-wrapper {
  background-color: var(--background-color);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link-wrapper {
  display: flex;
  flex-direction: column;
  > a {
    text-decoration: none;
    color: #000000;
    &:hover {
      text-decoration: underline;
    }
    &.is-head {
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
}
.line {
  width: 100%;
  height: 2px;
  background-color: #000000;
  margin: 16px 0;
}

.social-wrapper {
  display: flex;
  gap: 12px;
  > img {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
}
</style>