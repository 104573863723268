<template>
  <div class="products-container responsive-p">
    <div class="top-header-wrapper">
      <button @click="back" v-if="!isDesktop">
        <img src="@/assets/icons/primary-back.svg" alt="back">
      </button>
      <p class="title">{{ t('allProducts.title') }}</p>
    </div>
    <div class="filter-wrapper">
      <div class="filter-display">
        <div
          v-for="(filterTab, index) in filterTabs"
          :key="index"
          :class="{'active': sorting.value.includes(filterTab.value)}"
          @click="selectTab(filterTab)"
        >
          <p>
            {{ filterTab.title }}
          </p>
          <img
            src="@/assets/icons/arrow-up-thin.svg"
            alt="order"
            :class="{'rotate': sorting.value.includes('-')}"
            v-if="sorting.value.includes(filterTab.value)"
          >
        </div>
      </div>
    </div>
    <div v-if="displayedProducts" class="products-wrapper">
      <ProductCard
        v-for="(product, index) in displayedProducts"
        :key="index"
        :title="product.name"
        :price="numberWithCommas(product.price)"
        :sold="numberWithCommas(product.sold)"
        :thumbnail="product.thumbnail"
        @click="goToProduct(product)"
      />
    </div>
  </div>
</template>

<script>
import { computed, watch, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import useDebounceRef from '@/composables/useDebounceRef'
import numberWithCommas from '@/utils/numberWithCommas'
import { useScreenSize } from '@/utils/isDesktop'

import ProductCard from '@/components/ProductCard'

export default {
  name: 'AllProducts',
  components: {
    ProductCard,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()
    const { isDesktop } = useScreenSize()

    // const query = useDebounceRef('', 500)
    const showFilter = ref(false)
    const selectedTab = ref({
      title: t('filter.tab.name'),
      value: 'name',
    })
    const filterTabs = [
      {
        title: t('filter.tab.name'),
        value: 'name',
      },
      {
        title: t('filter.tab.price'),
        value: 'price',
      },
      {
        title: t('filter.tab.discount'),
        value: 'discount',
      },
      {
        title: t('filter.tab.sold'),
        value: 'sold',
      },
      {
        title: t('filter.tab.numVisits'),
        value: 'num_visits',
      },
    ]

    /** comouted */
    const filteredProducts = computed(() => store.state.products.data.filteredProducts)
    const recommendProducts = computed(() => store.state.frontpage.data.recommendProducts)
    const priceFrom = computed(() => store.state.filter.data.priceFrom)
    const priceTo = computed(() => store.state.filter.data.priceTo)
    const sorting = computed(() => store.state.filter.data.sorting)
    const selectedCategories = computed(() => store.state.filter.data.categories)
    const displayedProducts = computed(() => filteredProducts.value?.length > 0 ? filteredProducts.value : recommendProducts.value)
    const query = useDebounceRef(computed({
      get: () => store.state.filter.data.text,
      set: (val) => store.commit('filter/SET_TEXT', val)
    }), 500)

    /** method */
    const back = () => router.back()
    const goToProduct = product => router.push(`/product/${product.id}?name=${product.name}&desc=${product.description}&price=${product.price}&image=${product.thumbnail}&b=1`)
    const fetchProducts = (newQuery) => {
      const q = newQuery ? newQuery : query.value
      const queryBody = {
        query: q,
        price_from: priceFrom.value,
        price_to: priceTo.value,
        sorting: sorting.value.value,
        category: selectedCategories.value.join(','),
      }
      store.dispatch('products/fetchGetProductsByKeywords', queryBody)
    }
    const selectTab = tab => {
      if (sorting.value.value === tab.value && sorting.value.value.includes('-')) {
        store.commit('filter/SET_SORTING', tab)
      }
      else if (sorting.value.value === tab.value) {
        store.commit('filter/SET_SORTING', {
          ...tab,
          value: `-${tab.value}`
        })
      }
      else {
        store.commit('filter/SET_SORTING', tab)
      }
      fetchProducts()
    }

    /** mounted */
    onMounted(() => {
      fetchProducts()
      store.dispatch('frontpage/fetchFrontpageData')
    })

    /** watch */
    watch(query, newQuery => {
      fetchProducts(newQuery)
    })

    return {
      t,
      displayedProducts,
      goToProduct,
      back,
      query,
      showFilter,
      fetchProducts,
      priceFrom,
      priceTo,
      filterTabs,
      numberWithCommas,
      selectedTab,
      selectTab,
      isDesktop,
      sorting
    }
  }
}
</script>

<style lang="scss" scoped>

.products-container {
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 780px) {
    padding-top: 16px !important;
  }
}

.top-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
  }
  > button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
  }
}

.filter-wrapper {
  // background: var(--secondary-color);
  // padding: 12px 8px;
  > .filter-input-wrapper {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    button {
      border: 0;
      outline: none;
      background-color: transparent;
      position: relative;
      margin-left: 8px;
      display: flex;
      align-items: center;
      color: var(--secondary-color);
      &:active {
        opacity: 0.8;
      }
    }
  }
}
.filter-display {
  background-color: #fff;
  // border-radius: 4px;
  display: flex;
  border-bottom: 1px solid var(--grey-color);
  > div {
    cursor: pointer;
    flex: 1;
    font-size: 18px;
    padding: 8px;
    color: var(--grey-color);
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    > p {
      white-space: nowrap;
    }
    > img {
      width: 12px;
      transform: rotate(180deg);
      &.rotate {
        transform: rotate(0);
      }
    }
    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 50%;
        width: 1px;
        background-color: var(--grey-color);
      }
    }
    &.active {
      color: var(--secondary-color);
      border-bottom: 2px solid var(--secondary-color);
    }
  }
  span {
    font-size: 18px;
    color: #afafaf;
  }
}

.products-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 24px;
  @media screen and (min-width: 780px) {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
}
</style>
