<template>
  <HomeTopBar />
  <router-view class="router-wrapper"/>
  <!-- <MainTabBar v-if="!pagesExcludeMainTab.includes($route.name)" /> -->
  <Loading />
  <SnackBar />
  <PrivacyPopup />
  <Footer />
  <CopyrightFooter />
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
// import MainTabBar from '@/components/MainTabBar'
import Loading from '@/components/Loading'
import SnackBar from '@/components/SnackBar'
import HomeTopBar from '@/components/HomeTopBar'
import PrivacyPopup from '@/components/PrivacyPopup'
import CopyrightFooter from '@/components/CopyrightFooter'
import Footer from '@/components/Footer'

export default {
  components: {
    // MainTabBar,
    Loading,
    SnackBar,
    HomeTopBar,
    PrivacyPopup,
    CopyrightFooter,
    Footer,
  },
  setup() {
    /** context */
    const store = useStore()
    const { locale } = useI18n()

    /** constant */
    const pagesExcludeMainTab = [
      'ProductById',
      'Checkout',
      'MyAddresses',
      'NewAddress',
      'EditAddress',
      'SelectAddress',
      'MyOrders',
      'Products',
      'UserLogin',
      'UserLoginWithUsername',
      'UserLoginWithPhone',
      'ForgetPassword',
      'SetNewPassword',
      'FavoriteProducts',
      'Coupon',
      'CategoryProducts',
      'MyOrderById',
      'Redirect',
      'OrderCancellation',
      'CreditCard',
      'CreditCardOtp',
      'VerifyUser',
      'Recommend',
      'Frequency',
      'LatestReceive',
      'LatestPurchase',
      'ContinueDrugs',
      'LaserService',
      'LaserServiceById',
      'LaserServiceReserveById',
      'MyContinueDrugs',
      'AboutUs',
      'Referral',
    ]

    onMounted(() => {
      let url = new URL(window.location.href);
      let params = new URLSearchParams(url.search);
      try {
        params.forEach((value, key) => {
          if (key.startsWith('utm_')) {
            localStorage.setItem('ads_url', url);
            localStorage.setItem('ads_date', new Date().toISOString());
          }
        });
        
      } catch (error) {
        console.error('Error parsing URL:', error);
      }

      store.dispatch('user/fetchGetUserProfile').then(() => {
        const user = store.getters['user/getUserProfile'];

        const makalinUser = JSON.parse(localStorage.getItem('makalin-user')) || {};
        let utmParams = {};

        try {
          params.forEach((value, key) => {
            if (key.startsWith('utm_')) {
              utmParams[key] = value;
            }
          });
        } catch (error) {
          console.error('Error parsing URL:', error);
        }
        let data = {
          "user_id": user.id || 0,
          "session_key": makalinUser.data?.access || "",
          "event": "click_open",
          "user_agent": navigator.userAgent,
          "referer": document.referrer,
          "url": window.location.href,
          "info": utmParams,
          "created": new Date().toISOString(),
        };
        store.dispatch('order/fetchSaveLog', data);
      });
      store.dispatch('cart/fetchGetCart');
      const currentLang = localStorage.getItem('language');
      if (currentLang) {
        locale.value = currentLang;
      }
    })
    return {
      pagesExcludeMainTab,
    }
  }
}
</script>

<style>
@import '@/styles/_variables.scss';
@import '@/styles/fonts.scss';
@import '@/styles/global.scss';

body, html, #app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  overflow-y: auto;
}

@media screen and (min-width: 780px) {
  body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #app {
    background-color: var(--white-color);
    width: 100%;
    min-width: 100vh;
    display: flex;
    flex-direction: column;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'NotoSansThai';
  font-size: 18px;
  touch-action: manipulation;
}

h1 {
  font-size: 2em !important;
  font-weight: bold;
  > * {
    font-size: 1em !important;
    font-weight: bold;
  }
}

h2 {
  font-size: 1.5em !important;
  > * {
    font-size: 1em !important;
  }
}
h3 {
  font-size: 1.17em !important;
  > * {
    font-size: 1em !important;
  }
}
h4 {
  font-size: 1em !important;
  > * {
    font-size: 1em !important;
  }
}
h5 {
  font-size: 0.83em !important;
  > * {
    font-size: 1em !important;
  }
}
h6 {
  font-size: 0.67em !important;
  > * {
    font-size: 1em !important;
  }
}

</style>
