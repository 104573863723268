<template>
  <div class="edit-profile responsive-p">
    <BackTopBar />
    <NavigationBar :items="navItems" />
    <img :src="displayedProfileImage" alt="profile" class="main-profile">
    <p v-if="selectedFile" class="change-profile" @click="saveProfileImage">{{ t('save') }}</p>
    <p class="change-profile" v-else @click="triggerFileInput">{{ t('changeProfileImg') }}</p>
    <input type="file" accept="capture=camera,image/*" ref="file" style="display: none" @change="setSelectedFile" />
    <TextInput
      :label="`${t('profile.firstName')}:`"
      v-model="firstName"
      name="firstName"
      required
    />
    <TextInput
      :label="`${t('profile.lastName')}:`"
      v-model="lastName"
      name="lastName"
      required
    />
    <TextInput
      :label="`${t('profile.displayName')}:`"
      v-model="displayName"
      name="displayName"
      required
    />
    <TextInput
      :label="`${t('profile.email')}:`"
      v-model="email"
      name="email"
      type="email"
      required
    />
    <TextInput
      :label="`${t('profile.tel')}:`"
      v-model="phone"
      name="phone"
      disabled
    />
    <TextInput
      type="date"
      :label="`${t('profile.dob')}:`"
      v-model="dob"
      name="dob"
      required
    />
    <TextInput
      type="number"
      :label="`${t('profile.weight')}:`"
      v-model="weight"
      name="weight"
      required
    />
    <TextInput
      type="number"
      :label="`${t('profile.height')}:`"
      v-model="height"
      name="height"
      required
    />
    <DropdownInput
      label="เพศ:"
      v-model="selectedGender"
      name="gender"
      :items="genderItems"
    />
    <MultipleInsert
      :label="t('profile.allergicDrug.label')"
      v-model="allergicDrugs"
      :noDataLabel="t('profile.allergicDrug.noData')"
      :placeholder="t('profile.allergicDrug.placeholder')"
    />
    <MultipleInsert
      :label="t('profile.congenitalDisease.label')"
      v-model="congenitalDiseases"
      :noDataLabel="t('profile.congenitalDisease.noData')"
      :placeholder="t('profile.congenitalDisease.placeholder')"
    />
    <MultipleInsert
      :label="t('profile.personalMedicine.label')"
      v-model="personalMedicines"
      :noDataLabel="t('profile.personalMedicine.noData')"
      :placeholder="t('profile.personalMedicine.placeholder')"
    />
    <MButton class="mt-a" @click="save">
      {{ t('save') }}
    </MButton>
  </div>
</template>

<script>
import { ref, computed, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import BackTopBar from '@/components/BackTopBar'
import TextInput from '@/components/Form/TextInput'
import DropdownInput from '@/components/Form/DropdownInput'
import MButton from '@/components/Form/MButton'
import MultipleInsert from '@/components/Form/MultipleInsert'
import NavigationBar from '@/components/NavigationBar'


export default {
  name: 'editProfile',
  components: {
    BackTopBar,
    TextInput,
    DropdownInput,
    MButton,
    MultipleInsert,
    NavigationBar,
  },
  setup() {
    /** context */
    const store = useStore()
    const { t } = useI18n()

    /** constant */
    const genderItems = [
      { id: 1, label: t('male'), value: 'male' },
      { id: 2, label: t('female'), value: 'female' },
    ]
    const navItems = computed(() => [
      {
        name: t('menu.home'),
        path: '/'
      },
      {
        name: t('menu.profile'),
        path: '/my-profile'
      },
      {
        name: t('profile.editProfile')
      }
    ])
    /** reactive */
    const firstName = ref('')
    const lastName = ref('')
    const displayName = ref('')
    const email = ref('')
    const phone = ref('')
    const dob = ref('')
    const weight = ref('')
    const height = ref('')
    const allergicDrugs = ref([])
    const congenitalDiseases = ref([])
    const personalMedicines = ref([])
    const file = ref(null)
    const selectedFile = ref(null)
    const displaySelectedFile = ref(null)
    const selectedGender = ref({ id: 1, label: t('male'), value: 'male' })

    /** computed */
    const user = computed(() => store.getters['user/getUserProfile'])
    const displayedProfileImage = computed(() => displaySelectedFile.value ? displaySelectedFile.value : user.value?.thumbnail_url)
    
    watchEffect(() => {
      if (user.value) {
        firstName.value = user.value.first_name
        lastName.value = user.value.last_name
        displayName.value = user.value.display_name
        email.value = user.value.email
        phone.value = user.value.phone
        dob.value = user.value.dob
        weight.value = user.value.weight
        height.value = user.value.height
        allergicDrugs.value = user.value.allergic_drug
        congenitalDiseases.value = user.value.congenital_disease
        personalMedicines.value = user.value.personal_medicine
        const foundGenderItem = genderItems.find(item => item.value === user.value.gender)
        if (foundGenderItem) selectedGender.value = foundGenderItem
      }
    })

    /** method */
    const save = () => {
      if (!email.value) {
        store.commit('snackBar/SET_DATA', { message: t('profile.pleaseFillEmail'), color: 'warning' })
        return
      }
      if (!dob.value) {
        store.commit('snackBar/SET_DATA', { message: t('profile.pleaseFillBoD'), color: 'warning' })
        return
      }
      if (!height.value) {
        store.commit('snackBar/SET_DATA', { message: t('profile.pleaseFillHegiht'), color: 'warning' })
        return
      }
      if (!weight.value) {
        store.commit('snackBar/SET_DATA', { message: t('profile.pleaseFillWeight'), color: 'warning' })
        return
      }
      const body = {
        first_name: firstName.value,
        last_name: lastName.value,
        display_name: displayName.value,
        email: email.value,
        phone: phone.value,
        dob: dob.value,
        weight: weight.value,
        height: height.value,
        allergic_drug: allergicDrugs.value,
        congenital_disease: congenitalDiseases.value,
        personal_medicine: personalMedicines.value,
        gender: selectedGender.value.value,
      }
      store.dispatch('user/fetchUpdateUser', body)
    }
    const triggerFileInput = () => {
      file.value.click()
    }
    const setSelectedFile = (event) => {
      selectedFile.value = event.target.files[0]
      displaySelectedFile.value = URL.createObjectURL(event.target.files[0])
    }
    const saveProfileImage = () => {
      store.dispatch('user/fetchUpdateAvatar', { file: selectedFile.value, callback: () => {
        selectedFile.value = null
        displaySelectedFile.value = null
      }})
    }

    return {
      t,
      genderItems,
      firstName,
      lastName,
      displayName,
      email,
      phone,
      dob,
      weight,
      height,
      allergicDrugs,
      personalMedicines,
      congenitalDiseases,
      selectedGender,
      save,
      file,
      selectedFile,
      triggerFileInput,
      displayedProfileImage,
      setSelectedFile,
      saveProfileImage,
      navItems
    }
  }
}
</script>

<style lang="scss" scoped>

.edit-profile {
  padding: 54px 16px;
  display: flex;
  flex-direction: column;
  // height: 100%;
  padding-bottom: 76px;
  margin-top: 24px;
  overflow-y: auto;
  @media screen and (min-width: 780px) {
    padding-top: 16px;
    padding-bottom: 16px;
    height: calc(100% - 74px);
    margin-top: 24px;
  }
}

.main-profile {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.change-profile {
  margin: 0 auto;
  margin-top: 8px;
  color: var(--secondary-color);
  font-size: 1.2rem;
  text-decoration: underline;
  cursor: pointer;
}
</style>
