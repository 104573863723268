<template>
  <div class="sub-footer-wrapper">
    <div class="max-width-wrapper">
      <img src="@/assets/images/white-logo.png" alt="logo">
      <div class="content-wrapper">
        <p class="title">{{ subFooter.title }}</p>
        <span class="description">{{ subFooter.description }}</span>
      </div>
      <MButton @click="goToVerify">
        Get Offer
      </MButton>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { getItem } from '@/api/cms/query'

import MButton from './Form/MButton'

const subFooter = ref({
  title: '',
  description: '',
})

const store = useStore()
const router = useRouter()

const user = computed(() => store.getters['user/getUserProfile'])


onMounted(async() => {
  const subSubFooterRespose = await getItem('sub_footer')
  subFooter.value = {
    title: subSubFooterRespose.translations.title,
    description: subSubFooterRespose.translations.description,
  }
})

const goToVerify = () => {
  if (user.value) {
    store.commit('user/SET_SHOW_VERIFY', false)
    router.push('/user/verify')
  }
  else {
    router.push('/user/login')
  }
}
</script>

<style lang="scss" scoped>

.sub-footer-wrapper {
  width: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url('@/assets/images/bg-sub-banner.jpg');
}

.max-width-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  > img {
    width: 124px;
  }
  > button {
    white-space: nowrap;
  }
  @media screen and (min-width: 780px) {
    flex-direction: row;
    padding: 48px 76px;
  }

}

.content-wrapper {
  margin-right: auto;
  .title {
    color: #FFFFFF;
    font-size: 1.8rem;
    font-weight: bold;
  }
  .description {
    color: #FFFFFF;
    font-size: 1.2rem;
  }
}
</style>