<template>
  <div class="products-container responsive-p">
    <div class="top-header-wrapper">
      <button @click="back">
        <img src="@/assets/icons/primary-back.svg" alt="back">
      </button>
      <p class="title">{{ category?.title }}</p>
    </div>
    <NavigationBar :items="navItems" />
    <div v-if="products" class="products-wrapper">
      <ProductCard
        v-for="(product, index) in products"
        :key="index"
        :title="product.name"
        :price="numberWithCommas(product.price)"
        :sold="numberWithCommas(product.sold)"
        :thumbnail="product.thumbnail"
        @click="goToProduct(product)"
        :isLaser="laserCategoryId == id"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useScreenSize } from '@/utils/isDesktop'
import numberWithCommas from '@/utils/numberWithCommas'
import laserCategoryId from '@/constants/laserCategoryId'

import ProductCard from '@/components/ProductCard'
import NavigationBar from '@/components/NavigationBar'

export default {
  name: 'CategoryProducts',
  components: {
    ProductCard,
    NavigationBar,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { t } = useI18n()
    const { isDesktop } = useScreenSize

    /** constant */
    const id = route.params.id
    /** computed */
    const category = computed(() => store.state.products.data.productsByCategoryId?.category)
    const products = computed(() => store.state.products.data.productsByCategoryId?.products)
    const navItems = computed(() => {
      return [
        {
          name: t('menu.home'),
          path: '/'
        },
        {
          name: t('product.title'),
          path: '/products'
        },
        {
          name: category?.value?.title,
        },
      ]
    })
    
    /** method */
    const back = () => router.back()
    const goToProduct = product => {
      if (laserCategoryId == id) {
        router.push(`/laser-service/${product.id}?name=${product.name}&desc=${product.description}&price=${product.price}&image=${product.thumbnail}&b=1`)
      }
      else {
        router.push(`/product/${product.id}?name=${product.name}&desc=${product.description}&price=${product.price}&image=${product.thumbnail}&b=1`)
      }
    }

    /** mounted */
    onMounted(() => {
      store.dispatch('products/fetchGetProductsByCategoryId', id)
    })

    return {
      id,
      t,
      back,
      goToProduct,
      products,
      category,
      numberWithCommas,
      laserCategoryId,
      isDesktop,
      navItems,
    }
  }
}
</script>

<style lang="scss" scoped>

.products-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 56px;
  height: 100%;
  @media screen and (min-width: 780px) {
    padding-top: 0;
    margin-top: 12px;
  }
}

.top-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 8;
  @media screen and (min-width: 780px) {
    display: none;
  }
  > button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
  }
}

.products-wrapper {
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 24px;
  @media screen and (min-width: 780px) {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
}
</style>
