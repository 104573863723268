<template>
  <div class="order-card-wrapper">
    <div class="detail-wrapper">
      <p class="order-id">{{ t('order.orderId') }}: {{ item?.order_number }}</p>
      <p class="order-id">{{ t('order.shipping') }}: {{ item?.shipping_method_text }}</p>
      <p class="order-id">{{ t('order.paymentMethod') }}: {{ item?.payment_channel_text }}</p>
      <p class="order-id">{{ t('order.total') }}: {{ numberWithCommas(item?.grand_total) }}</p>
    </div>
    <div class="status-wrapper">
      {{ item?.status_thai }}
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import numberWithCommas from '@/utils/numberWithCommas'

export default {
  props: {
    item: {
      type: Object,
      default: null,
    }
  },
  setup() {
    /** context */
    const { t } = useI18n()

    return {
      t,
      numberWithCommas,
    }
  }
}

</script>

<style lang="scss" scoped>
.order-card-wrapper {
  border: 1px solid #C3C3C3;
  border-radius: 8px;
  padding: 16px 12px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.detail-wrapper {
  > p {
    margin-bottom: 4px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
