import { createWebHistory, createRouter } from 'vue-router'
import store from '@/store'

import Home from '@/views'
import Products from '@/views/products'
import ProductSearch from '@/views/products/search'
import Redirect from '@/views/redirect'
import MyDrugs from '@/views/my-drugs'
import MyCart from '@/views/my-cart'
import CreditCard from '@/views/credit-card'
import CreditCardOtp from '@/views/credit-card/otp'
import Checkout from '@/views/my-cart/checkout'
import Coupon from '@/views/my-cart/coupon'
import MyAddresses from '@/views/my-addresses'
import MyContinueDrugs from '@/views/my-continue-drugs'
import NewAddress from '@/views/my-addresses/new'
import EditAddress from '@/views/my-addresses/_id/edit'
import SelectAddress from '@/views/my-addresses/select'
import MyOrders from '@/views/my-orders'
import MyOrderById from '@/views/my-orders/_id'
import OrderCancellation from '@/views/my-orders/_id/cancellation'
import LatestReceive from '@/views/my-drugs/latest-receive'
import ContinueDrugs from '@/views/my-drugs/continue-drugs'
import Recommend from '@/views/my-drugs/recommend'
import Frequency from '@/views/my-drugs/frequency'
import LatestPurchase from '@/views/my-drugs/latest-purchase'
import ProductById from '@/views/products/_id'
import FavoriteProducts from '@/views/products/favorite'
import CategoryProducts from '@/views/products/category/_id'
import Profile from '@/views/profile'
import EditProfile from '@/views/profile/edit'
import UserLogin from '@/views/user/login'
import UserLoginWithPhone from '@/views/user/login-with-phone'
import UserLoginWithUsername from '@/views/user/login-with-username'
import ForgetPassword from '@/views/user/forget-password'
import SetNewPassword from '@/views/user/forget-password/set-new-password'
import VerifyUser from '@/views/user/verify'
import LaserService from '@/views/laser-service'
import LaserServiceById from '@/views/laser-service/_id'
import LaserServiceReserveById from '@/views/laser-service/_id/reserve'
import AboutUs from '@/views/about-us'
import PrivacyPolicy from '@/views/privacy-policy'
import TermsAndConditions from '@/views/terms-and-conditions'
import RegisterUser from '@/views/user/register'
import ReferralView from '@/views/referral'
import QRScanView from '@/views/qrscan'
import AllLocation from '@/views/locations'
import LocationById from '@/views/locations/_id'
import AllBlog from '@/views/blogs'
import BlogById from '@/views/blogs/_id'
import AllPromotion from '@/views/promotions'
import PromotionById from '@/views/promotions/_id'
import PsaInstruction from '@/views/psa-instruction'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
  },
  {
    path: '/products/search',
    name: 'ProductSearch',
    component: ProductSearch,
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: Redirect,
  },
  {
    path: '/my-drugs',
    name: 'MyDrugs',
    component: MyDrugs,
  },
  {
    path: '/my-cart',
    name: 'MyCart',
    component: MyCart,
  },
  {
    path: '/credit-card',
    name: 'CreditCard',
    component: CreditCard,
  },
  {
    path: '/credit-card/otp',
    name: 'CreditCardOtp',
    component: CreditCardOtp,
  },
  {
    path: '/my-cart/checkout',
    name: 'Checkout',
    component: Checkout,
  },
  {
    path: '/my-cart/coupon',
    name: 'Coupon',
    component: Coupon,
  },
  {
    path: '/my-addresses',
    name: 'MyAddresses',
    component: MyAddresses,
  },
  {
    path: '/my-addresses/new',
    name: 'NewAddress',
    component: NewAddress,
  },
  {
    path: '/my-addresses/:id/edit',
    name: 'EditAddress',
    component: EditAddress,
  },
  {
    path: '/my-addresses/select',
    name: 'SelectAddress',
    component: SelectAddress,
  },
  {
    path: '/my-orders',
    name: 'MyOrders',
    component: MyOrders,
  },
  {
    path: '/my-orders/:id',
    name: 'MyOrderById',
    component: MyOrderById,
  },
  {
    path: '/my-orders/:id/cancellation',
    name: 'OrderCancellation',
    component: OrderCancellation,
  },
  {
    path: '/my-drugs/latest-receive',
    name: 'LatestReceive',
    component: LatestReceive,
  },
  {
    path: '/my-drugs/recommend',
    name: 'Recommend',
    component: Recommend,
  },
  {
    path: '/my-drugs/frequency',
    name: 'Frequency',
    component: Frequency,
  },
  {
    path: '/my-drugs/latest-purchase',
    name: 'LatestPurchase',
    component: LatestPurchase,
  },
  {
    path: '/my-drugs/continue-drugs',
    name: 'ContinueDrugs',
    component: ContinueDrugs,
  },
  {
    path: '/product/:id',
    name: 'ProductById',
    component: ProductById,
  },
  {
    path: '/product/favorite',
    name: 'FavoriteProducts',
    component: FavoriteProducts,
  },
  {
    path: '/product/category/:id',
    name: 'CategoryProducts',
    component: CategoryProducts,
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: Profile,
  },
  {
    path: '/my-continue-drugs',
    name: 'MyContinueDrugs',
    component: MyContinueDrugs,
  },
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: EditProfile,
  },
  {
    path: '/user/login',
    name: 'UserLogin',
    component: UserLogin,
  },
  {
    path: '/user/login-with-username',
    name: 'UserLoginWithUsername',
    component: UserLoginWithUsername,
  },
  {
    path: '/user/register',
    name: 'Register',
    component: RegisterUser,
  },
  {
    path: '/user/referral',
    name: 'Referral',
    component: ReferralView,
  },
  {
    path: '/user/login-with-phone',
    name: 'UserLoginWithPhone',
    component: UserLoginWithPhone,
  },
  {
    path: '/user/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword,
  },
  {
    path: '/user/forget-password/set-new-password',
    name: 'SetNewPassword',
    component: SetNewPassword,
  },
  {
    path: '/user/verify',
    name: 'VerifyUser',
    component: VerifyUser,
  },
  {
    path: '/laser-service',
    name: 'LaserService',
    component: LaserService
  },
  {
    path: '/laser-service/:id',
    name: 'LaserServiceById',
    component: LaserServiceById
  },
  {
    path: '/laser-service/:id/reserve',
    name: 'LaserServiceReserveById',
    component: LaserServiceReserveById
  },
  {
    path: '/qrscan/:u',
    name: 'qrscan',
    component: QRScanView
  },
  {
    path: '/locations',
    name: 'AllLocation',
    component: AllLocation,
  },
  {
    path: '/locations/:id',
    name: 'LocationById',
    component: LocationById,
  },
  {
    path: '/blogs',
    name: 'AllBlog',
    component: AllBlog,
  },
  {
    path: '/blogs/:id',
    name: 'BlogById',
    component: BlogById,
  },
  {
    path: '/promotions',
    name: 'AllPromotion',
    component: AllPromotion,
  },
  {
    path: '/promotions/:id',
    name: 'PromotionById',
    component: PromotionById,
  },
  {
    path: '/psa-instruction',
    name: 'PsaInstruction',
    component: PsaInstruction,
  },
]


const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  store.commit('loading/RESET')
  const redirectTo = to.query.redirectTo
  if (redirectTo) {
    return next(redirectTo)
  }
  
  next()
})

export default router
