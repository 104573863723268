<template>
  <div class="blog-section">
    <div class="card over-span">
      <p class="title">{{ title }}</p>
      <span class="description">
        {{ description }}
      </span>
      <m-button outline @click="goToAllBlog">
        {{  t('blog.explore') }}
      </m-button>
    </div>
    <div
      class="card blog-item"
    >
      <div
        class="blog-name"
        v-for="(item, index) in evenOder"
        :key="index"
        @click="goToBlog(item)"
      >
        <p>
          {{ item.title }}
        </p>
        <span>></span>
      </div>
    </div>
    <div
      class="card blog-item"
    >
      <div
        class="blog-name"
        v-for="(item, index) in oddOder"
        :key="index"
      >
        <p>
          {{ item.title }}
        </p>
        <span>></span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { getItem, getItems } from '@/api/cms/query'

import MButton from './Form/MButton'

const { t } = useI18n()

const items = ref([])
const title = ref('')
const description = ref('')

onMounted(async () => {
  const blogs = await getItems('blogs', 20, {}, '&sort[]=-date_created')
  const blogHeader = await getItem('blog_header')
  if (blogHeader?.translations) {
    title.value = blogHeader.translations.title
    description.value = blogHeader.translations.description
  }
  items.value = blogs.map(blog => {
    return {
      title: blog.translations.name,
      id: blog.id
    }
  })
})

const oddOder = computed(() => items.value.filter((_, index) => index % 2 !== 0))
const evenOder = computed(() => items.value.filter((_, index) => index % 2 === 0))
const router = useRouter()

const goToAllBlog = () => {
  router.push('/blogs')
}

const goToBlog = (item) => {
  router.push(`/blogs/${item.id}`)
}
</script>

<style lang="scss" scoped>

.blog-section {
  max-width: 1440px;
  display: grid;
  padding: 32px 0;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding: 0 16px;
  @media screen and (min-width: 780px) {
    padding: 0 76px;
  }
  @media screen and (max-width: 435px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
  @media screen and (min-width: 780px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.card {
  display: flex;
  flex-direction: column;
  min-width: 180px;
  gap: 16px;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
}

.title {
  font-size: 1.8rem;
  font-weight: bold;
}

.description {
  font-size: 1.2rem;
}

.img-wrapper {
  aspect-ratio: 4 / 3;
}
.blog-item {
  padding: 0;
  gap: 0;
}

.blog-name {
  padding: 6px 0;
  width: 100%;
  color: var(--secondary-color);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    > p {
      text-decoration: underline;
    }
  }
  &:first-child {
    border-top: 1px solid var(--grey-color);
    @media screen and (max-width: 435px) {
      border-top: 0;
    }
  }
  border-bottom: 1px solid var(--grey-color);
  > p {
    font-size: 1.2rem;
  }
}

.over-span {
  grid-column: span 2;
  @media screen and (min-width: 780px) {
    grid-column: span 1;
  }
  @media screen and (max-width: 435px) {
    grid-column: span 1;
    margin-bottom: 16px;
  }
}
</style>
