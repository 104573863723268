<template>
  <div class="container responsive-p">
    <div class="top-header-wrapper">
      <button @click="back" v-if="!isDesktop">
        <img src="@/assets/icons/primary-back.svg" alt="back">
      </button>
      <p class="title">{{ t('myContinueDrugs.title') }}</p>
    </div>
    <div class="types-wrapper">
      <div class="type-wrapper">
        My Skin Routine
      </div>
      <div class="selectable-wrapper">
        <div
          :class="`routine-wrapper${selectedContinueDrugType.value === routine.value ? ' active' : ''}`"
          v-for="routine in continueDrugTypes"
          :key="routine.key"
          @click="selectContinueDrugType(routine)"
        >
          <img :src="require(`@/assets/icons/continue-drugs/${routine.value}${selectedContinueDrugType.value === routine.value ? '-active' : ''}.svg`)" :alt="routine.key">
          <span>{{ t(`myContinueDrugs.${routine.value}`) }}</span>
        </div>
      </div>
    </div>
    <div class="content-wrapper" v-if="continueDrugs.length > 0">
      <p class="continue-title mb-2">
        ยาที่กำลังใช้ - Continue Drugs ({{ continueDrugs.length }})
      </p>
      <p class="continue-description mb-2">
        กรุณาอ่าน/ดูวิดิโอวิธีใช้ยาก่อนทุกครั้ง หากคุณไม่แน่ใจ สามารถติดต่อแอดมินของคลินิก เพื่อขอข้อมูลเพิ่มเติม
      </p>
      <div class="contact-admin-line mb-2" @click="askLine">
        <img src="@/assets/icons/line.svg" alt="line" class="line-logo">
        <p>ติดต่อแอดมินคลินิก</p>
        <img src="@/assets/icons/next.svg" alt="next" class="next ml-a">
      </div>
      <div class="items-wrapper">
        <ContinueDrug
          v-for="item in continueDrugs"
          :key="item.id"
          :item="item"
          :onClick="selectDrug"
        />
      </div>
      <MButton :disabled="currentlyUsedItems.length === 0" class="mb-2" fullWidth outline-secondary @click="showUnusedDrugs">{{ t('myContinueDrugs.setUnusedDrugs') }}</MButton>
      <div class="select-all mr-a mb-2" @click="selectAll">
        <div class="check-box all" :class="{ 'is-checked': selectedDrugs.length === outOfStockItems?.length }" v-if="outOfStockItems.length > 0">
          <div class="inside">
            <img src="@/assets/icons/small-check.svg" alt="check">
          </div>
        </div>
        <p class="title ml-1">
          {{ t('myContinueDrugs.unusedDrugs') }} ({{ outOfStockItems.length }})
        </p>
      </div>
      <div class="items-wrapper">
        <SelectableContinueDrug
        v-for="item in outOfStockItems"
        :key="item.id"
        :item="item"
        :selectedDrugs="selectedDrugs"
        :toggleSelectProduct="toggleSelectProduct"
        />
      </div>
      <MButton :disabled="outOfStockItems.length === 0" class="mb-2" fullWidth outline-secondary @click="showUsingDrugs">{{ t('myContinueDrugs.setUsingDrugs') }}</MButton>
      <MButton :disabled="selectedDrugs.length === 0" class="mb-2" fullWidth @click="addProductToCart">
        <img src="@/assets/icons/basket.svg" alt="basket">
        <p class="ml-1">
          {{ t('myDiaryDrugs.addSelect') }}
        </p>
      </MButton>
      <!-- <p class="title mb-2">
        {{ t('myContinueDrugs.usingDrugs') }}
      </p> -->
    </div>
    <div v-else class="no-continue-drugs-wrapper">
      <img src="@/assets/images/no-continue-drugs.svg" alt="no-continue-drugs" class="mb-2">
      <span class="no-item-text">{{ t('myContinueDrugs.noItem') }}</span>
    </div>
  </div>
  <ContinueDrugModal
    v-if="selectedDrug"
    :item="selectedDrug"
    :deselectDrug="deselectDrug"
  />
  <UpdateProductStockModal
    :items="continueDrugs"
    :updateProductStock="updateProductStock"
    :deselectUpdateProductStock="() => updateProductStock = null"
  />
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import liff from '@line/liff'
import { useScreenSize } from '@/utils/isDesktop'

import ContinueDrug from '@/components/ContinueDrug'
import ContinueDrugModal from '@/components/ContinueDrugModal'
import UpdateProductStockModal from '@/components/UpdateProductStockModal'
import MButton from '@/components/Form/MButton'
import SelectableContinueDrug from '@/components/SelectableContinueDrug'

export default {
  name: 'MyContinueDrugs',
  components: {
    ContinueDrug,
    ContinueDrugModal,
    UpdateProductStockModal,
    MButton,
    SelectableContinueDrug,
  },
  setup() {
    const { t } = useI18n()
    const router = useRouter()
    const store = useStore()
    const { isDesktop } = useScreenSize()

    const selectedDrug = ref(null)
    const updateProductStock = ref(null)
    const selectedContinueDrugType = ref({ key: '', value: 'all' })
    const continueDrugTypes = [
      { key: '', value: 'all' },
      { key: 'is_dose_morning', value: 'morning' },
      { key: 'is_dose_afternoon', value: 'afternoon' },
      { key: 'is_dose_evening', value: 'evening' },
      { key: 'is_dose_beforebed', value: 'night' },
    ]
    const selectedDrugs = ref([])

    /** computed */
    const continueDrugs = computed(() => {
      const items = store.getters['user/getHowToUseMyContinueDrugs'] ? store.getters['user/getHowToUseMyContinueDrugs'] : []
      if (selectedContinueDrugType.value.key === '') return items
      const filteredItems = items.filter(item => item[selectedContinueDrugType.value.key])
      
      return filteredItems
    })
    const outOfStockItems = computed(() => continueDrugs.value.filter(item => item.product_stock_type === 'out_of_stock'))
    const currentlyUsedItems = computed(() => continueDrugs.value.filter(item => item.product_stock_type === 'currently_used'))

    /** method */
    const selectAll = () => {
      if (selectedDrugs.value.length === outOfStockItems.value?.length) {
        selectedDrugs.value = []
      }
      else {
        selectedDrugs.value = outOfStockItems.value.map(product => product.id)
      }
    }

    const toggleSelectProduct = (item) => {
      const found = selectedDrugs.value.includes(item.id)
      if (found) {
        selectedDrugs.value = selectedDrugs.value.filter(p => p !== item.id)
      }
      else {
        selectedDrugs.value.push(item.id)
      }
    }
    const showUsingDrugs = () => {
      if (outOfStockItems.value.length > 0) updateProductStock.value = 'out_of_stock'
    }

    const showUnusedDrugs = () => {
      if (currentlyUsedItems.value.length > 0) updateProductStock.value = 'currently_used'
    }
    const back = () => router.replace('/my-profile')

    const selectDrug = (item) => {
      selectedDrug.value = item
    }

    const deselectDrug = () => {
      selectedDrug.value = null
    }

    const selectContinueDrugType = (type) => {
      selectedContinueDrugType.value = type
    }

    const addProductToCart = async () => {
      await selectedDrugs.value.forEach(async id => {
        await store.dispatch('cart/fetchAddProduct', {
          product_id: id,
          quantity: 1,
          add_from: 'user',
        })
      })
      selectedDrugs.value = []
    }

    const askLine = () => {
      const isInClient = liff.isInClient()
      const message = 'สอบถามยาต่อเนื่อง'
      if (!isInClient) {
        const os = liff.getOS()
        if (os === 'android' || os === 'ios') {
          window.open(`https://line.me/R/oaMessage/${process.env.VUE_APP_LINE_AT_ID}/?${encodeURIComponent(message)}`, '_blank')
        }
        else if (os === 'web') {
          window.open(process.env.VUE_APP_LINE_URL_OFFICIAL_ACCOUNT, '_blank')
        }
      }
      else if (isInClient) {
        window.open(`https://line.me/R/oaMessage/${process.env.VUE_APP_LINE_AT_ID}/?${encodeURIComponent(message)}`, '_blank')
      }
    }

    onMounted(() => {
      store.dispatch('user/fecthHowToUseMyContinueDrugs')
    })
    return {
      t,
      back,
      continueDrugs,
      selectedDrug,
      selectDrug,
      deselectDrug,
      continueDrugTypes,
      selectedContinueDrugType,
      selectContinueDrugType,
      askLine,
      updateProductStock,
      outOfStockItems,
      currentlyUsedItems,
      toggleSelectProduct,
      selectAll,
      selectedDrugs,
      addProductToCart,
      showUsingDrugs,
      showUnusedDrugs,
      isDesktop,
    }
  }
}

</script>

<style lang="scss" scoped>

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  overflow-y: scroll;
  padding-top: 56px;
  @media screen and (min-width: 780px) {
    padding-top: 0;
    padding-bottom: 0;
    height: calc(100% - 74px);
  }
}


.top-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  @media screen and (min-width: 780px) {
    position: relative;
    border-bottom: 0;
  }
  > button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.content-wrapper {
  padding: 16px 16px 0;
}
.continue-title {
  font-size: 24px;
  font-weight: bold;
}
.continue-description {
  color: #878787
}

.contact-admin-line {
  border: 1px solid #C3C3C3;
  border-radius: 6px;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 8px 12px;
  &:active {
    opacity: 0.7;
  }
  > p {
    margin-left: 12px;
  }
}

.items-wrapper {
  padding: 12px 0;
  border-top: 2px solid #C3C3C3;
}

.no-continue-drugs-wrapper {
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-item-text {
  font-size: 1.25rem;
  color: #C3C3C3;
  font-weight: bold;
}

.types-wrapper {
  background-color: #D2D6F9;

  .type-wrapper {
    text-align: center;
    padding: 24px 0;
    font-size: 1.5rem;
    font-weight: bold;
  }
}
.selectable-wrapper {
  display: flex;
  .routine-wrapper {
    padding: 8px 4px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border-top: 1px solid #ffffff;
    &:not(:last-child) {
      border-right: 1px solid #ffffff;
    }
    &:active {
      opacity: 0.7;
    }
    &.active {
      background-color: #4352E0;
      span {
        color: white;
      }
    }
    img {
      width: 24px;
      height: 24px;
      margin-bottom: 4px;
    }
    span {
      font-size: 0.95rem;
      color: #4A58E5;
      text-align: center;
    }
  }
}

.check-box {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 2px solid var(--secondary-color);
  border-radius: 4px;
  overflow: hidden;
  &.all {
    position: static;
    // top: inherit;
    // right: inherit;
  }
  .inside {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);
    background-color: var(--secondary-color);
    transition: all 0.2s ease;
    > img {
    }
  }
  &.is-checked {
    border: 2px solid var(--secondary-color);
    > .inside {
      transform: scale(1);
    }
  }
}

.select-all {
  display: flex;
  align-items: center;
  color: #000;
}
</style>
