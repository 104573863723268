<template>
  <div class="loading-container" v-if="isLoading">
    <div class="layer-wrapper">
      <div class="layer"></div>
      <div class="layer"></div>
      <div class="layer"></div>
    </div>
    <p class="flash">{{ t('loading') }} ...</p>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default {
  name: 'LoadingComp',
  setup() {
    /** context */
    const store = useStore()
    const { t } = useI18n()

    /** computed */
    const isLoading = computed(() => store.getters['loading/isLoading'])

    return {
      t,
      isLoading,
    }
  }
}
</script>
<style lang="scss" scoped>
.loading-container {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
}

.layer-wrapper {
  height: 120px;
  width: 120px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.layer {
  display: block;
  position: absolute;
  height: 70px;
  width: 70px;
  border-radius: 10px;
  transform: rotateX(50deg) rotateY(0deg) rotate(45deg) translateY(50%) translate3d(0,0,0);
  &:nth-of-type(3) {
    background: rgba(30,50,105, .07);
    margin-top: 30px;
    -webkit-animation: movedown 1.2s cubic-bezier(.39, .575, .565, 1) infinite normal;
    animation: movedown 1.2s cubic-bezier(.39, .575, .565, 1) infinite normal;
  }
  &:nth-of-type(2) {
    background: rgba(30,50,105, .18);
    margin-top: 20px;
    -webkit-animation: moveup 1.3s cubic-bezier(.39, .575, .565, 1) infinite normal;
    animation: moveup 1.3s cubic-bezier(.39, .575, .565, 1) infinite normal;
  }
  &:nth-of-type(1) {
    -webkit-animation: moveup 1s cubic-bezier(.39, .575, .565, 1) infinite normal;
    animation: moveup 1s cubic-bezier(.39, .575, .565, 1) infinite normal;
    background-color: #fff;
    box-shadow: 6px 6px 0 0 var(--secondary-color);
    background: url('@/assets/logo.jpg');
    background-size: cover;
  }
}

.flash {
  animation: blinker .9s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes movedown {
  0%, 60%, 100% {
    transform: rotateX(50deg) rotateY(0deg) rotate(45deg) translateZ(0);
  }
  25% {
    transform: rotateX(50deg) rotateY(0deg) rotate(45deg) translateZ(-1em);
  }
}

@keyframes moveup {
  0%, 60%, 100% {
    transform: rotateX(50deg) rotateY(0deg) rotate(45deg) translateZ(0);
  }
  25% {
    transform: rotateX(50deg) rotateY(0deg) rotate(45deg) translateZ(1em);
  }
}
</style>