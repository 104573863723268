<template>
  <div class="sub-banner-wrapper">
    <div class="max-width-wrapper">
      <div class="content-wrapper">
        <p class="title">
          {{ banner.title }}
        </p>
        <span class="description">
          {{ banner.description }}
        </span>
        <MButton @click="goToPSA">
          PSA Analysis
        </MButton>
      </div>
      <div class="image-wrapper">
        <div class="image">
          <img :src="banner.image" :alt="banner.title">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import liff from '@line/liff'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import { getItem } from '@/api/cms/query'
import MButton from './Form/MButton'

const router = useRouter()

const banner = ref({
  title: '',
  description: '',
  iamge: ''
})

onMounted(async() => {
  const subBannerRespose = await getItem('sub_banner')
  banner.value = {
    image: `${process.env.VUE_APP_CMS_BASE_IMAGE_URL}/${subBannerRespose.image}`,
    title: subBannerRespose.translations.title,
    description: subBannerRespose.translations.description,
  }
})

const goToPSA = () => {
  const isInClient = liff.isInClient()
  if (isInClient) {
    window.location.href = process.env.VUE_APP_PSA_URL
  }
  else {
    router.push('/psa-instruction')
  }
}
</script>

<style lang="scss" scoped>
.sub-banner-wrapper {
  width: 100%;
  display: flex;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  background-image: url('@/assets/images/bg-sub-banner.jpg');
}

.max-width-wrapper {
  max-width: 1440px;
  width: 100%;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 72px;
  @media screen and (min-width: 780px) {
    flex-direction: row;
    padding: 48px 76px;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  align-items: flex-start;
}

.title {
  color: #FFFFFF;
  font-size: 1.6rem;
  font-weight: bold;
}

.description {
  color: #FFFFFF;
  font-size: 1.2rem;
}

.image-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 780px) {
    justify-content: center;
    align-items: flex-end;
  }
}

.image {
  min-width: 320px;
  width: 50%;
  > img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 100%;
  }
}

</style>
