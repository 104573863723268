<template>
  <div class="navigation-bar-wrapper">
    <div
      :class="`nav-item${item.path ? ' cursor-pointer' : ''}`"
      v-for="(item, index) in props.items"
      :key="index"
      @click="goTo(item)"
    >
      <img
        v-if="index !== 0 && item.name"
        src="@/assets/icons/grey-forward.svg"
        alt="grey-forward"
      >
      <p v-if="item.name">{{ item.name }}</p>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  }
})

const router = useRouter()

const goTo = item => {
  if (item.path) {
    router.push(item.path)
  }
}

</script>

<style lang="scss" scoped>
.navigation-bar-wrapper {
  display: none;
  @media screen and (min-width: 780px) {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 8px 0;
  }
  .nav-item {
    display: flex;
    gap: 8px;
    > img {
      transform: scale(0.8);
    }
    > p {
      color: #878787;
      font-size: 16px;
    }
    &:last-child {
      > p {
        color: #000000;
        font-weight: bold;
      }
    }
  }
}
</style>
