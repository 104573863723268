<template>
  <div class="login-wrapper responsive-p">
    <div class="login-box">
      <p class="back" @click="backToLogin" v-if="!isDesktop">&lt; {{ t('back') }}</p>
      <p class="title">{{ t('setNewPassword') }}</p>
      <div class="login-with-username">
        <TextInput v-model="newPassword" :label="t('newPassword')" type="password" />
        <TextInput v-model="reNewPassword" :label="t('reNewPassword')" type="password" />
        <MButton @click="confirmResetPassword" :disabled="!isValid">{{ t('confirm') }}</MButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useScreenSize } from '@/utils/isDesktop'

import MButton from '@/components/Form/MButton'
import TextInput from '@/components/Form/TextInput'

export default {
  name: 'SetNewPassword',
  components: {
    MButton,
    TextInput,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()
    const { isDesktop } = useScreenSize()

    /** reactive */
    const newPassword = ref('')
    const reNewPassword = ref('')

    /** computed */
    const isValid = computed(() => newPassword.value === reNewPassword.value)

    /** method */
    const confirmResetPassword = () => {
      store.dispatch('user/fetchResetPassword', {
        newPassword: newPassword.value,
        reNewPassword: reNewPassword.value,
      })
    }
    const backToLogin = () => {
      router.replace('/user/login')
    }

    return {
      t,
      confirmResetPassword,
      backToLogin,
      newPassword,
      reNewPassword,
      isValid,
      isDesktop
    }
  }
}
</script>

<style lang="scss" scoped>

.login-wrapper {
  height: 100%;
  @media screen and (min-width: 780px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 74px);
  }
}

.login-box {
  background-color: var(--background-color);
  background-image: url('@/assets/images/bg-logo.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 28px 60px;
  @media screen and (min-width: 780px) {
    height: auto;
    width: 100%;
    min-width: 500px;
    border-radius: 8px;
    padding: 24px 28px;
  }
}

.log-in-with-username {
  padding: 0 40px;
}

button {
  width: 100%;
}

.back {
  color: var(--secondary-color);
  margin-bottom: 12px;
  font-size: 1.2rem;
  padding: 12px;
  cursor: pointer;
}

.description {
  color: var(--secondary-color);
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 24px;
}

.title {
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 24px;
  color: var(--primary-color);
}
</style>
