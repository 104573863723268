<template>
  <p class="highlight-header">
    {{ title }}
  </p>
  <div class="highlight-wrapper">
    <div
      class="highlight-item"
      v-for="(item, index) in items"
      :key="index"
      @click="onClick(item)"
    >
      <div class="content-wrapper">
        <div class="highlight-image">
          <img :src="item.image" :alt="item.translations.title">
        </div>
        <p class="title">{{ item.translations.title }}</p>
        <span class="description">
          {{ item.translations.description }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getItem, getItems } from '@/api/cms/query'

const items = ref([])
const title = ref('')

const router = useRouter()

onMounted(async () => {
  const highlightHeader = await getItem('sub_highlight_header')
  title.value = highlightHeader.translations.title
  const highlights = await getItems('highlights', 3, { is_main_highlight: false })
  items.value = highlights.map(highlight => ({
    ...highlight,
    image: `${process.env.VUE_APP_CMS_BASE_IMAGE_URL}/${highlight.preview_image}`,
  }))
})

const onClick = item => {
  if (item.link.startsWith('http')) {
    window.open(item.link, '_blank')
  }
  else {
    router.push(item.link)
  }
}
</script>

<style lang="scss" scoped>
.highlight-header {
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  max-width: 1440px;
}
.highlight-wrapper {
  max-width: 1440px;
  display: flex;
  gap: 48px;
  justify-content: center;
  padding: 0 16px;
  flex-direction: column;
  @media screen and (min-width: 780px) {
    flex-direction: row;
    padding: 0 76px;
  }
}

.highlight-item {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  @media screen and (min-width: 780px) {
    width: 30%;
  }
  &:hover {
    transform: scale(1.01);
  }
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.highlight-image {
  overflow: hidden;
  > img {
    object-fit: cover;
    aspect-ratio: 16/9;
    width: 100%;
  }
}

.title {
  font-weight: bold;
  font-size: 1.5rem;
  margin: 8px 0;
}

.description {
  color: #878787;
  margin: 8px 0 8px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
