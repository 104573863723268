<template>
  <div class="locations-wrapper responsive-p">
    <BackTopBar v-if="!isDesktop" />
    <NavigationBar :items="navItems" />
    <div class="content-wrapper main">
      <p class="title">
        {{ title }}
      </p>
      <span class="description">
        {{ description }}
      </span>
    </div>
    <div class="location-items-wrapper">
      <div
        class="location-item"
        v-for="(item, index) in items"
        :key="index"
      >
        <div class="location-image">
          <img :src="item.image" :alt="item.title">
        </div>
        <div class="content-wrapper">
          <p class="title">{{ item.title }}</p>
          <span class="description">
            {{ item.city }}
          </span>
          <div class="links">
            <a outline @click="goToLocation(item)">
              {{ t('location.detail') }}
            </a>
            <a outline @click="onClick(item)">
              {{ t('location.map') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { getItem, getItems } from '@/api/cms/query'
import { useScreenSize } from '@/utils/isDesktop'

import NavigationBar from '@/components/NavigationBar'
import BackTopBar from '@/components/BackTopBar'

defineComponent({
  name: 'AllLocation'
})

const router = useRouter()
const { isDesktop } = useScreenSize()

const title = ref('')
const description = ref('')
const items = ref([])

onMounted(async () => {
  const locations = await getItems('locations')
  const locationHeader = await getItem('location_header')
  if (locationHeader) {
    title.value = locationHeader.translations.title
    description.value = locationHeader.translations.description
  }
  items.value = locations.map(location => {
    return {
      title: location.translations.name,
      image: `${process.env.VUE_APP_CMS_BASE_IMAGE_URL}/${location.image}`,
      description: location.translations.description,
      phone: location.phone,
      city: location.translations.city,
      link: location?.link,
      id: location.id
    }
  })
})

const { t } = useI18n()

const navItems = computed(() => [
  {
    name: t('menu.home'),
    path: '/'
  },
  {
    name: title?.value,
  }
])

const onClick = item => {
  if (item.link.startsWith('http')) {
    window.open(item.link, '_blank')
  }
  else {
    router.push(item.link)
  }
}

const goToLocation = item => {
  router.push(`/locations/${item.id}`)
}
</script>

<style lang="scss" scoped>
.locations-wrapper {
  padding: 24px;
  margin-top: 64px;
  @media screen and (min-width: 780px) {
    margin: 24px;
  }
}

.content-wrapper {
  .title {
    font-size: 1.8rem;
    font-weight: bold;
  }
  .description {
    font-size: 1.4rem;
  }
  &.main {
    margin-bottom: 24px;
  }
}

.location-items-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 48px;
}

.location-item {
  display: flex;
  gap: 0px;
  flex-direction: column;
  @media screen and (min-width: 780px) {
    flex-direction: row;
    gap: 48px;
  }
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  @media screen and (min-width: 780px) {
    padding: 16px 0;
  }
}

.location-image {
  flex: 1;
  border-radius: 24px;
  overflow: hidden;
  object-fit: cover;
  > img {
    width: 100%;
    height: 100%;
  }
}

.links {
  display: flex;
  flex-direction: column;
  gap: 4px;
  > a {
    text-decoration: underline;
    color: var(--secondary-color);
    cursor: pointer;
  }
}
</style>
