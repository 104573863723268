import { ref, onMounted, onUnmounted, computed } from 'vue';

export function useScreenSize() {
  const windowWidth = ref(window.innerWidth);  // Reactive reference to store window width

  // Function to update windowWidth
  const updateWindowWidth = () => {
    windowWidth.value = window.innerWidth;  // Update window width on resize
  };

  // Add resize event listener when the component is mounted
  onMounted(() => {
    window.addEventListener('resize', updateWindowWidth);
  });

  // Clean up event listener when the component is destroyed
  onUnmounted(() => {
    window.removeEventListener('resize', updateWindowWidth);
  });

  // Reactive computed property for checking desktop
  const isDesktop = computed(() => {
    return windowWidth.value >= 780;  // Determine if it's a desktop screen
  });

  return {
    windowWidth,  // Expose window width if needed
    isDesktop,    // Expose computed property for checking desktop
  };
}
