<template>
  <div class="blogs-wrapper responsive-p">
    <!-- <BackTopBar /> -->
    <NavigationBar :items="navItems" />
    <div class="content-wrapper main">
      <p class="title">
        {{ title }}
      </p>
      <span class="description">
        {{ description }}
      </span>
    </div>
    <div class="sort-by-wrapper">
      <DropdownInput
        :label="t('blog.sortBy')"
        :items="sortByItems"
        v-model="sort"
      />
    </div>
    <div class="blog-items-wrapper">
      <div
        class="blog-item"
        v-for="(item, index) in items"
        :key="index"
      >
        <div class="blog-image">
          <img :src="item.image" :alt="item.title" @click="goToBlog(item)">
        </div>
        <div class="content-wrapper">
          <p class="title" @click="goToBlog(item)">{{ item.title }}</p>
          <span class="description">
            {{ item.description }}
          </span>
          <div class="created-at">
            <p>{{ t('blog.createdBy') }}: <b>{{ item.author }}</b></p>
            <MButton @click="goToBlog(item)">{{ t('blog.read') }}</MButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { getItem, getItems } from '@/api/cms/query'

import MButton from '@/components/Form/MButton'
import NavigationBar from '@/components/NavigationBar'
import DropdownInput from '@/components/Form/DropdownInput'

defineComponent({
  name: 'AllBlog'
})

const router = useRouter()
const { t } = useI18n()

const title = ref('')
const description = ref('')
const items = ref([])

const sort = ref({ value: 'desc', label: t('blog.new') })
const sortFilter = computed(() => sort.value.value === 'desc' ? '-date_created' : '+date_created')

const fetchData = async () => {
  const blogs = await getItems('blogs', 100, {}, `&sort[]=${sortFilter.value}`)
  const blogHeader = await getItem('blog_header')
  if (blogHeader) {
    title.value = blogHeader?.translations?.title
    description.value = blogHeader?.translations?.description
  }
  items.value = blogs.map(blog => {
    return {
      title: blog.translations.name,
      image: `${process.env.VUE_APP_CMS_BASE_IMAGE_URL}/${blog.preview_image}`,
      author: blog.translations.author,
      content: blog.translations.content,
      description: blog.translations.description,
      id: blog.id
    }
  })
}

onMounted(async () => {
  await fetchData()
})

watch(sort, async () => {
  await fetchData()
})

const sortByItems = [
  { value: 'desc', label: t('blog.new') },
  { value: 'asc', label: t('blog.old') },
]

const navItems = computed(() => [
  {
    name: t('menu.home'),
    path: '/'
  },
  {
    name: title?.value,
  }
])

const goToBlog = item => {
  router.push(`/blogs/${item.id}`)
}
</script>

<style lang="scss">
.blogs-wrapper {
  padding: 24px;
  margin-top: 64px;
  @media screen and (min-width: 780px) {
    margin: 24px;
  }
}

.content-wrapper {
  margin-bottom: 24px;
  .title {
    font-size: 1.8rem;
    font-weight: bold;
  }
  .description {
    font-size: 1.2rem;
  }
}

.blog-items-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 48px;
}

.blog-item {
  display: flex;
  gap: 0px;
  flex-direction: column;
  @media screen and (min-width: 780px) {
    flex-direction: row;
    gap: 48px;
  }
  .content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    @media screen and (min-width: 780px) {
      padding: 16px 0;
    }
    .title {
      font-size: 1.8rem;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .description {
      font-size: 1.2rem;
    }
  }
}


.blog-image {
  flex: 1;
  border-radius: 24px;
  overflow: hidden;
  object-fit: cover;
  > img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.created-at {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 4px;
  width: 100%;
  > a {
    text-decoration: underline;
    color: var(--secondary-color);
    cursor: pointer;
  }
}

.sort-by-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  > .dropdown-wrapper {
    align-items: flex-end;
  }
}
</style>
